import { createSlice } from '@reduxjs/toolkit'
import { Tag } from 'depoto-core'
import { ValueTypes } from '../../../zeus'

export type OrderUpdate = Partial<Exclude<ValueTypes['RootSchemaMutation']['updateOrder'], undefined>[0]>

export interface OrderDetailsSlice {
  orderBuffer?: OrderUpdate // Changes to the order that are not yet saved
}

export const initialState: OrderDetailsSlice = {
  orderBuffer: {},
}

export const orderDetailsSlice = createSlice({
  name: 'orderDetails',
  initialState,
  reducers: {
    setPrivateNote: (state, { payload }: { payload: string }) => {
      state.orderBuffer!.privateNote = payload
    },
    setNote: (state, { payload }: { payload: string }) => {
      console.log({ payload })
      state.orderBuffer!.note = payload
    },
    setTags: (state, { payload }: { payload: Tag['id'][] }) => {
      console.log({ payload })
      state.orderBuffer!.tags = payload
    },
  },
})

export const { setPrivateNote, setNote } = orderDetailsSlice.actions

export default orderDetailsSlice.reducer
