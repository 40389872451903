import React, { Fragment, useEffect, useRef, useState } from 'react'

import { Dialog, Transition, TransitionChild } from '@headlessui/react'
import { Btn } from './Btn'
import { FormInput } from './Form/FormInput'
import { useCore } from '../hooks'
import { KeyEventType } from '../lib'
import { FormTextarea } from './Form/FormTextarea'
import { FormSelect } from './Form/FormSelect'
import { EVENT_SHOW_NOTIFICATION } from './Notification'
import { OrderItemForReturn, ReturnItem } from '../store/core/reducer'
import { generateUuidV4 } from 'depoto-core'
import Modal from './Modal'
import { useForm } from 'react-hook-form'
import { ControlledInput } from './Form/Controlled/ControlledInput'
import { ControlledSelect } from './Form/Controlled/ControlledSelect'
import { ControlledTextarea } from './Form/Controlled/ControlledTextarea'
import { toast } from 'react-toastify'

type Props = {
  isDisabled?: boolean
  orderItem: OrderItemForReturn
}
export const EditReturnItemModal: React.FC<Props> = ({ isDisabled, orderItem }) => {
  const { isFetching, currentOrder, depots, orderReturns, setOrderReturns } = useCore()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const oItemFromOrder = currentOrder?.items.find(oi => orderItem.id === oi.id)
  const returnsDepotId =
    currentOrder?.checkout?.returnsDepot?.id > 0
      ? currentOrder.checkout.returnsDepot.id
      : oItemFromOrder?.moves?.[0]?.productDepotFrom?.depot?.id
      ? oItemFromOrder?.moves[0].productDepotFrom.depot.id
      : depots?.[0]?.id

  const { control, handleSubmit, reset, watch, setValue } = useForm({
    defaultValues: {
      uuid: generateUuidV4(),
      amount: orderItem.quantity - (orderItem.quantityReturned || 0) || 0,
      depotId: Number(returnsDepotId),
      batch: orderItem.batch || '',
      expirationDate: orderItem.expirationDate || '',
      position1: orderItem.moves[0]?.position1 || '',
      position2: orderItem.moves[0]?.position2 || '',
      position3: orderItem.moves[0]?.position3 || '',
      note: orderItem.note || '',
    },
  })

  const alreadyInQueue =
    (currentOrder?.id &&
      orderReturns[currentOrder.id]
        ?.filter(ri => ri.orderItem.id === orderItem.id)
        ?.reduce((sum, ri) => sum + ri.amount, 0)) ||
    0

  // Reset form with new UUID when modal opens
  useEffect(() => {
    if (isOpen) {
      reset(() => ({
        uuid: generateUuidV4(),
        amount: orderItem.quantity - (orderItem.quantityReturned || 0) - alreadyInQueue || 0,
        depotId: Number(returnsDepotId),
        batch: orderItem.batch || '',
        expirationDate: orderItem.expirationDate || '',
        position1: orderItem.moves[0]?.position1 || '',
        position2: orderItem.moves[0]?.position2 || '',
        position3: orderItem.moves[0]?.position3 || '',
        note: orderItem.note || '',
      }))
    }
  }, [isOpen])

  const processPosition = (c: CustomEvent | any) => {
    if (c?.detail?.length === 3) {
      const pos = c?.detail
      setValue('position1', pos[0])
      setValue('position2', pos[1])
      setValue('position3', pos[2])
    }
  }

  useEffect(() => {
    document.addEventListener(KeyEventType.BARCODE, processCode)
    return () => {
      document.removeEventListener(KeyEventType.BARCODE, processCode)
    }
  }, [])

  const processCode = (c: CustomEvent | any) => {
    const barcode = c?.detail
    // handle rare occasion, when Order has more than one item with same ean / code - shopify fuckup
    const isCodeUnique =
      currentOrder &&
      currentOrder.items.filter(
        oi => oi.code === barcode || oi.product?.code === barcode || oi.ean === barcode || oi.product?.ean === barcode,
      ).length === 1
    if (!isCodeUnique) {
      toast.warning('Naskenovaný kód není v objednávce unikátní!')
      return
    }
    if (barcode === (orderItem.ean || orderItem.product?.ean)) {
      console.log('processCode: ', barcode, orderItem.name, orderItem.id, { orderItem })
      openModal()
    }
    if (barcode === (orderItem.code || orderItem.product?.code)) {
      console.log('processCode: ', barcode, orderItem.name, orderItem.id, { orderItem })
      openModal()
    }
  }

  function closeModal() {
    setIsOpen(false)
    document.removeEventListener(KeyEventType.POSITION, processPosition)
    document.removeEventListener(KeyEventType.ESC, closeModal)
  }

  function openModal() {
    setIsOpen(true)
    document.addEventListener(KeyEventType.POSITION, processPosition)
    document.addEventListener(KeyEventType.ESC, closeModal)
  }

  const confirmReturn = handleSubmit(formData => {
    const nextOrderReturns = { ...orderReturns }
    const orderId = orderItem.order?.id || currentOrder?.id

    if (orderId && orderId > 0) {
      const existingReturnsForOrderItem = nextOrderReturns[orderId]
        ? nextOrderReturns[orderId].filter(
            ri => ri.orderItem?.id === orderItem.id && ri.product?.id === orderItem.product?.id,
          )
        : []
      const alreadyReturnedAmount = existingReturnsForOrderItem.map(ri => ri.amount).reduce((a, b) => a + b, 0)

      if (alreadyReturnedAmount + formData.amount > (orderItem?.quantity || 0) - (orderItem?.quantityReturned || 0)) {
        toast.error(
          `Není možné vrátit požadovaný počet kusů, maximální možné množství k vrácení je ${
            (orderItem?.quantity || 0) - (orderItem?.quantityReturned || 0) - alreadyReturnedAmount
          }ks`,
        )
      } else {
        const returnItem: ReturnItem = {
          ...formData,
          orderItem,
          product:
            orderItem.product?.name?.length! > 0 ? orderItem.product : { ...orderItem.product, name: orderItem.name },
        }

        const uniqueReturnsForOrder = nextOrderReturns[orderId] ? [...nextOrderReturns[orderId]] : []
        uniqueReturnsForOrder.push(returnItem)
        nextOrderReturns[orderId] = uniqueReturnsForOrder
        setOrderReturns(nextOrderReturns)
      }
    }
    closeModal()
  })

  return (
    <>
      <Btn
        className={'btn-primary'}
        icon={'fa-undo'}
        title={'Vrátit'}
        onClick={openModal}
        isLoading={isFetching}
        isDisabled={isDisabled}
      />

      <Modal isOpen={isOpen} onClose={closeModal} title={`Vyplnit vratku ${orderItem.name}`}>
        <div className="card-body gap-2">
          <div className={'flex flex-row gap-2'}>
            <ControlledInput
              control={control}
              name={'amount'}
              className={'flex-1'}
              type={'number'}
              inputclassName={'text-right'}
              label={'Množství'}
              min={0}
              max={orderItem.quantity}
              blurOnInit={isOpen}
            />
            <ControlledInput
              control={control}
              name={'expirationDate'}
              className={'flex-1'}
              type={'date'}
              inputclassName={'text-left'}
              label={'Expirace'}
            />
            <ControlledInput
              control={control}
              name={'batch'}
              className={'flex-1'}
              type={'text'}
              inputclassName={'text-right'}
              label={'Šarže'}
            />
          </div>
          <div className={'flex flex-row gap-2'}>
            <ControlledInput
              control={control}
              name={'position1'}
              className={'flex-1'}
              type={'text'}
              inputclassName={'text-right'}
              label={'Pozice 1'}
            />
            <ControlledInput
              control={control}
              name={'position2'}
              className={'flex-1'}
              type={'text'}
              inputclassName={'text-right'}
              label={'Pozice 2'}
            />
            <ControlledInput
              control={control}
              name={'position3'}
              className={'flex-1'}
              type={'text'}
              inputclassName={'text-right'}
              label={'Pozice 3'}
            />
          </div>
          <div className={'flex flex-row items-center'}>
            <div className={'mr-5 font-bold'}>Sklad:</div>
            <ControlledSelect
              control={control}
              name={'depotId'}
              options={depots.map(d => ({ label: d.name, value: d.id }))}
              className={'w-64'}
            />
          </div>
          <div className={'flex flex-row gap-2'}>
            <ControlledTextarea
              control={control}
              name={'note'}
              label={'Poznámka'}
              className={'w-full'}
              textAreaclassName={'h-56 w-full'}
            />
          </div>
        </div>

        <div className="mt-4 flex justify-end gap-2">
          <Btn
            onClick={closeModal}
            isLoading={isFetching}
            className={'btn-outline border-light-grey'}
            icon={'fa-ban'}
            children={'Zrušit'}
            title={'Zrušit'}
          />
          <Btn
            onClick={confirmReturn}
            className={'btn-primary'}
            icon={'fa-check'}
            children={'Nastavit'}
            title={'Nastavit'}
            isLoading={isFetching}
          />
        </div>
      </Modal>
    </>
  )
}
