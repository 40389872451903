import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { Dialog, DialogTitle, Transition, TransitionChild } from '@headlessui/react'
import { Btn } from './Btn'
import { FormInput } from './Form/FormInput'
import { removeEmptyProperties, wait } from '../lib'
import { ClearanceItem, ClearanceItemLocation, Fn, generateUuidV4, Order, ProductDepot } from 'depoto-core'
import { FormSelect } from './Form/FormSelect'
import { useCore } from '../hooks'
import Modal from './Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/pro-duotone-svg-icons'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { ControlledSelect } from './Form/Controlled/ControlledSelect'
import { ControlledInput } from './Form/Controlled/ControlledInput'
import Control from 'react-select/dist/declarations/src/components/Control'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useGQL } from '../lib/GQLContext'
import { error } from 'console'
import { toast } from 'react-toastify'
import { ValueTypes } from '../zeus/index'

type Props = {
  clearanceItem: ClearanceItem
  onUpdate: Fn
  order: Order
}

export const EditExpeditionBatchPositionModal: React.FC<Props> = ({ clearanceItem, onUpdate, order }) => {
  let [isOpen, setIsOpen] = useState<boolean>(false)
  const [locations, setLocations] = useState<(ClearanceItemLocation | any)[]>(clearanceItem.locations || [])
  const gql = useGQL()

  // We assume that each clearanceItem has only one productDepot
  const initialProductDepot = clearanceItem.productDepots[0]

  const { control, register, handleSubmit, watch } = useForm({
    defaultValues: {
      productDepots: [
        {
          depot: initialProductDepot.depot!.id,
          productDepot: initialProductDepot.id,
          amount: clearanceItem.amount,
        },
      ],
    },
  })

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'productDepots', // unique name for your Field Array
  })

  const productDepotsFormState = watch('productDepots')

  const amountsAgreed = useMemo(
    () => productDepotsFormState.reduce((acc, curr) => acc + curr.amount, 0) === clearanceItem.amount,
    [productDepotsFormState, clearanceItem.amount],
  )

  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation({
    mutationKey: ['updateClearanceItemLocations'],
    mutationFn: (data: ValueTypes['updateClearanceItemLocations'][]) =>
      toast.promise(
        gql('mutation')({
          updateClearanceItem: [
            {
              id: clearanceItem.id,
              order: order.id,
              locations: data,
            },
            {
              errors: true,
            },
          ],
        }),
        {
          pending: 'Ukládám...',
          error: 'Něco se pokazilo',
          success: 'Uloženo',
        },
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['order', order.id] })
    },
  })

  const { depots } = useCore()

  const handleSaveLocations = handleSubmit(data => {
    mutate(
      data.productDepots.map(el => {
        const productPD = clearanceItem.product?.productDepots.find(pd => Number(pd.id) === Number(el.productDepot))
        return removeEmptyProperties({
          depot: productPD?.depot!.id,
          productDepot: productPD?.id,
          batch: productPD?.batch,
          expirationDate: productPD?.expirationDate,
          amount: el.amount,
          position1: productPD?.position1,
          position2: productPD?.position2,
          position3: productPD?.position3,
        }) as ValueTypes['updateClearanceItemLocations']
      }),
    )
  })

  const allowedDepotIds = order?.checkout?.depots.map(d => d.id) || []
  const availableDepots = depots?.filter(d => allowedDepotIds.includes(d.id))
  const productDepots =
    clearanceItem.product?.productDepots
      .filter(pd => pd.quantityAvailable >= clearanceItem.amount && allowedDepotIds.includes(pd.depot!.id))
      .map(pd => ({
        ...pd,
        name: `P: ${pd.position.length > 2 ? pd.position : '-'} | Š: ${pd.batch ?? '-'} | E: ${
          pd.expirationDate ?? '-'
        }  (${pd.quantityStock} / ${pd.quantityReservation} / ${pd.quantityAvailable}ks)`,
      })) || []

  const addRow = () => {
    append({
      depot: availableDepots[0].id,
      productDepot: productDepots[0].id,
      amount: 0,
    })
  }

  const closeModal = () => setIsOpen(false)

  const openModal = async () => {
    setIsOpen(true)
  }

  return (
    <>
      <div className="flex flex-1 justify-end" onClick={openModal}>
        <div className="bg-primary-blue rounded-xl px-3 py-2 text-white flex items-center gap-2 text-md cursor-pointer select-none">
          <FontAwesomeIcon icon={faPen} size="lg" className="text-white" />
          Upravit
        </div>
      </div>

      <Modal
        isOpen={isOpen}
        onClose={closeModal}
        title={`Upravit položku ${clearanceItem.product?.fullName}`}
        titleClassName="px-2"
        className="px-4">
        <div className="flex flex-col gap-4 mt-4">
          <div className="max-h-[80vh] gap-3 overflow-y-auto p-2 flex flex-col">
            {fields.map((field, index) => (
              <div key={field.id} className={'flex flex-col flex-1 gap-2 shadow-md p-4 rounded-xl'}>
                <div className="flex flex-row items-end gap-3">
                  <ControlledSelect
                    name={`productDepots.${index}.depot`}
                    control={control}
                    className={'flex-1'}
                    label={'Sklad'}
                    value={field.depot}
                    options={(availableDepots || []).map(pd => ({ label: pd.name, value: pd.id }))}
                  />
                  <Controller
                    control={control}
                    name={`productDepots.${index}.amount`}
                    render={({ field: { onChange, value } }) => (
                      <Btn
                        className={'btn-square btn-danger flex-1'}
                        children={'-10'}
                        title={'Odebrat 10'}
                        onClick={() => onChange(value > 9 ? value - 10 : 0)}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={`productDepots.${index}.amount`}
                    render={({ field: { onChange, value } }) => (
                      <Btn
                        className={'btn-square btn-danger flex-1'}
                        children={'-1'}
                        title={'Odebrat 1'}
                        onClick={() => onChange(value > 0 ? value - 1 : 0)}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={`productDepots.${index}.amount`}
                    render={({ field: { onChange, value } }) => (
                      <FormInput
                        className={'flex-1'}
                        label={'Pocet'}
                        value={String(value)}
                        onChange={val => {
                          onChange(parseInt(val))
                        }}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={`productDepots.${index}.amount`}
                    render={({ field: { onChange, value } }) => (
                      <Btn
                        className={'btn-square btn-success flex-1'}
                        children={'+1'}
                        title={'Přidat 1'}
                        onClick={() => onChange(value + 1)}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={`productDepots.${index}.amount`}
                    render={({ field: { onChange, value } }) => (
                      <Btn
                        className={'btn-square btn-success flex-1'}
                        children={'+10'}
                        title={'Přidat 10'}
                        onClick={() => onChange(value + 10)}
                      />
                    )}
                  />
                </div>
                <div className="flex flex-row items-end gap-3">
                  <ControlledSelect
                    name={`productDepots.${index}.productDepot`}
                    control={control}
                    className={'flex-1'}
                    label={'Zasoba'}
                    value={field.productDepot}
                    options={(productDepots || []).map(pd => ({ label: pd.name, value: pd.id }))}
                  />
                  {fields.length > 1 && (
                    <Btn
                      className={'btn-danger btn-circle'}
                      icon={'minus'}
                      title={'Odebrat'}
                      isDisabled={isLoading}
                      onClick={() => remove(index)}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>

          <div className="flex justify-between gap-2 px-2">
            <Btn
              className={'btn-success btn-circle'}
              icon={'plus'}
              title={'Přidat'}
              isDisabled={isLoading}
              onClick={addRow}
            />
            <div className="flex gap-2">
              <Btn
                onClick={closeModal}
                className={'btn-outline border-light-grey'}
                icon={'fa-ban'}
                children={'Zrušit'}
                title={'Zrušit'}
                isDisabled={isLoading}
              />
              <Btn
                className={'btn-primary'}
                icon={'fa-check'}
                children={'Uložit'}
                title={'Uložit'}
                isDisabled={isLoading || !amountsAgreed}
                isLoading={isLoading}
                onClick={handleSaveLocations}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}
