import React from 'react'
import { Fn } from 'depoto-core'

type Props = {
  checked?: boolean
  disabled?: boolean
  label?: string
  onChange?: Fn
}

export const Checkbox: React.FC<Props> = ({ checked, disabled, label, onChange }) => {
  return (
    <div className="form-control">
      <label className="cursor-pointer label">
        <span className="label-text">{label}</span>
        <input
          type="checkbox"
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          className="checkbox checkbox-primary"
        />
      </label>
    </div>
  )
}
