import { Btn } from './Btn'
import React from 'react'

export type LoaderProps = {
  size?: 'fullscreen' | 'fill-container'
}

export const Loader: React.FC<LoaderProps> = ({ size = 'fullscreen' }) => {
  const classNames = `w-full z-50 overflow-hidden flex items-center justify-center ${
    size === 'fullscreen' ? 'fixed top-0 left-0 right-0 bottom-0 h-screen' : 'h-full'
  }`

  return (
    <div className={classNames}>
      <div className="loader ease-linear"></div>
      {window?._depoto && (
        <Btn
          className={'btn-primary ml-5'}
          // icon={'fa-undo'}
          title={'smazat cache'}
          onClick={() => {
            localStorage.removeItem('depoto-expeditions-v2auth_email')
            localStorage.removeItem('depoto-expeditions-v2auth_company')
            localStorage.removeItem('depoto-expeditions-v2auth_remember_me')
            localStorage.removeItem('depoto-expeditions-v2auth_client_type')
            localStorage.removeItem('depoto-expeditions-v2auth_data')
            localStorage.removeItem('depoto-expeditions-v2auth_is_authenticated')
            localStorage.removeItem('globalState')
            window?._depoto?.nw?.clearCache()
            alert('restartujte aplikaci')
          }}>
          Smazat cache
        </Btn>
      )}
    </div>
  )
}
