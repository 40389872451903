import { FieldValues, useController, UseControllerProps } from 'react-hook-form'
import { CompactInput, CompactInputProps } from '../CompactInput'

export type ControlledCompactInputProps<T extends FieldValues> = UseControllerProps<T> & Partial<CompactInputProps>

export function ControlledCompactInput<T extends FieldValues>({
  control,
  name,
  rules,
  defaultValue,
  ...rest
}: ControlledCompactInputProps<T>) {
  const { field, fieldState } = useController({
    control,
    name,
    rules,
    defaultValue,
  })

  return (
    <>
      <CompactInput {...rest} {...field} />
      {!!fieldState.error?.message && <span className={'text-red-500'}>{fieldState.error?.message}</span>}
    </>
  )
}
