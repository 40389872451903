import React from 'react'
import { Order } from 'depoto-core'

type Props = {
  order: Order
}

export function formatPrice(price: number, currencyId?: string) {
  let currency: string
  switch (currencyId) {
    default:
    case 'CZK':
      currency = 'Kč'
      break
    case 'EUR':
      currency = '€'
      break
    case 'USD':
      currency = '$'
      break
  }
  return currency === 'EUR' || currency === 'USD'
    ? `${currency} ${price.toFixed(2)}`
    : `${price.toFixed(2)} ${currency}`
}

export const OrderPrice: React.FC<Props> = ({ order }) => {
  return <div className="col-span-2 font-bold">{formatPrice(order.priceAll || 0, order?.currency?.id)}</div>
}
