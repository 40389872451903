import React from 'react'
import { PhotoSwipe } from './PhotoSwipe'
import { Pictogram } from './Pictogram'
import { ReturnItem } from '../store/core/reducer'
import { useCore } from '../hooks'
import { Btn } from './Btn'
import { formatDate } from '../lib'
import { ProductMove } from 'depoto-core'

type Props = {
  productMove: ProductMove
}

export const ProductMoveRow: React.FC<Props> = ({ productMove }) => {
  const { currentOrder, depots, orderReturns, setOrderReturns } = useCore()
  const thumbUrl = productMove.product?.mainImage?.thumbnails?.length
    ? productMove.product?.mainImage.thumbnails.filter(t => t.format === 'w135')[0].url
    : ''

  const imageUrl = productMove.product?.mainImage?.thumbnails?.length
    ? productMove.product?.mainImage.thumbnails.filter(t => t.format === 'w2048')[0].url
    : ''

  return (
    <>
      <div className={`grid grid-cols-12 leading-10`}>
        <div className="grid grid-cols-6 col-span-6 gap-2">
          <div className="col-end-1 self-center flex items-center pl-20">
            <PhotoSwipe
              alt={''}
              caption={`
                        ${productMove ? productMove.product?.name : ''} <br> 
                        ${productMove.product?.code ? 'Kód: ' + productMove.product.code : ''} <br> 
                        ${productMove.product?.ean ? 'EAN: ' + productMove.product.ean : ''}`}
              galleryImg={imageUrl}
              src={thumbUrl}
              thumbnails={''}
            />
          </div>
          <div className="col-span-5 self-center font-semibold">
            {productMove.product?.name}
            {productMove.product?.isFragile ? <Pictogram type={'fragile'} isInline={true} marginLeft={2} /> : null}
            {productMove.product?.isOversize ? <Pictogram type={'oversize'} isInline={true} marginLeft={2} /> : null}
          </div>
        </div>
        {/*<div className={'self-center whitespace-nowrap overflow-x-auto mr-4'}>{depots.find(d => d.id === productMove.productDepotTo.id)?.name || ''}</div>*/}
        <div className={'self-center whitespace-nowrap overflow-x-auto mr-4'}>
          {productMove.productDepotTo.depot?.name}
        </div>
        <div className={'self-center whitespace-nowrap overflow-x-auto mr-4 text-center'}>
          {productMove.expirationDate?.length > 0 ? formatDate(productMove.expirationDate) : ''}
        </div>
        <div className={'self-center whitespace-nowrap overflow-x-auto mr-4 text-center'}>{productMove.batch}</div>
        <div className={'self-center whitespace-nowrap overflow-x-auto mr-4 text-center'}>
          {productMove.position1}-{productMove.position2}-{productMove.position3}
        </div>
        <div className="place-self-center self-center">
          <div className="square">
            <span className="square-amount">{productMove.amount}</span>
          </div>
        </div>
        <div className="place-self-center self-center">&nbsp;</div>
      </div>
    </>
  )
}
