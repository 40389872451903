import React from 'react'
import { OrderInfo } from './OrderInfo'
import { OrderStateHistory } from './OrderStateHistory'
import { Order } from 'depoto-core'

type Props = {
  order: Order
}
export const OrderState: React.FC<Props> = ({ order }) => {
  return (
    <div className={'p-main flex gap-8'}>
      <div className={'flex-05'}>
        <OrderInfo order={order} />
      </div>
      <div className={'flex-1'}>
        <OrderStateHistory order={order} />
      </div>
    </div>
  )
}
