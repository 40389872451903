import React, { useContext, useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useHistory, useRouteMatch } from 'react-router-dom'
import { IconName, IconProp } from '@fortawesome/fontawesome-svg-core'
import 'react-datepicker/dist/react-datepicker.css'
import { Btn } from '../../components'
import { OrderDetailContext } from './OrderDetailContext'
import { Tag } from '../../components/Tag'

export const Header: React.FC<{ onSave: () => void; isDirty: boolean }> = ({ onSave, isDirty }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [openTab, setOpenTab] = useState<number>(1)
  const [searchIcon, setSearchIcon] = useState<IconName>('search')
  const history = useHistory()
  const { path } = useRouteMatch()

  const { order } = useContext(OrderDetailContext)

  const hasFragiles = order?.items?.filter(oi => oi.product?.isFragile).length! > 0
  const hasOversized = order?.items?.filter(oi => oi.product?.isOversize).length! > 0

  const menuItems = useMemo(
    () => [
      {
        label: 'Položky',
        icon: ['far', 'list-check'],
        link: `/order/${order?.id}/items`,
      },
      {
        label: 'Doprava',
        icon: ['far', 'truck-fast'],
        link: `/order/${order?.id}/shipping`,
      },
      {
        label: 'Poznámky',
        icon: ['far', 'notebook'],
        link: `/order/${order?.id}/notes`,
      },
      {
        label: 'Informace',
        icon: ['far', 'info-circle'],
        link: `/order/${order?.id}/states`,
      },
      {
        label: 'Soubory',
        icon: ['far', 'folders'],
        link: `/order/${order?.id}/files`,
      },
    ],
    [order?.id],
  )

  return (
    <nav className="bg-white border-b border-cool-grey flex justify-between items-center min-h-16 px-3">
      <div className="flex flex-row divide-x divide-cool-grey mr-4 items-center">
        <Btn
          onClick={() => history.push(`/dashboard`)}
          className={'btn-outline btn-sm btn-header border-light-grey mr-6'}
          children={'Zpět'}
          title={'Zpět'}
          icon={'arrow-left'}
        />
        <div>
          <div className="font-bold text-xl px-6">Obj. #{order?.id}</div>
          <div className="text-sm text-primary-blue font-semibold px-6">{order?.processStatus.name}</div>
        </div>
      </div>
      <div className={'flex gap-8 items-center py-2.5'}>
        <div className={'flex items-center gap-3 flex-wrap'}>
          {!order?.isPaid && (
            <div>
              <span className={'label-red'}>
                <FontAwesomeIcon icon={['far', 'money-bill']} size="lg" className={'mr-2'} />
                <span>Neuhrazeno</span>
              </span>
            </div>
          )}
          {hasFragiles && (
            <div>
              <span className={'label-yellow'}>
                <FontAwesomeIcon icon={['far', 'fragile']} size="lg" className={'mr-2'} />
                <span>Křehké</span>
              </span>
            </div>
          )}
          {hasOversized && (
            <div>
              <span className={'label-purple'}>
                <FontAwesomeIcon icon={['far', 'expand-alt']} size="lg" className={'mr-2'} />
                <span>Nadměrné</span>
              </span>
            </div>
          )}
          {(order?.tags || []).map(tag => (
            <div key={tag.id}>
              <Tag tag={tag} />
            </div>
          ))}
        </div>

        <ul className="flex items-center gap-2" role="tablist">
          {menuItems.map((el, i) => (
            <li className="text-center" key={el.label}>
              <Link
                to={el.link}
                className={
                  'text-sm font-semibold px-3 py-3 rounded flex align-center gap-3 flex-row flex-wrap justify-center ' +
                  (openTab === i + 1 ? 'bg-secondary-blue text-primary-blue' : 'bg-white text-black')
                }
                onClick={() => {
                  setOpenTab(i + 1)
                }}
                data-toggle="tab"
                role="tablist">
                <FontAwesomeIcon size={'xl'} icon={el.icon as IconProp} className="text-primary-blue self-center" />
                <span>{el.label}</span>
              </Link>
            </li>
          ))}
        </ul>
        <Btn
          onClick={onSave}
          isDisabled={!isDirty}
          className={'btn btn-primary btn-header btn-sm mr-2'}
          children={'Uložit'}
          title={'Uložit'}
          icon={'check'}
        />
      </div>
    </nav>
  )
}
