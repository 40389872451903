import { createContext, useContext } from 'react'
import { Thunder } from '../zeus'

export const GQLContext = createContext<ReturnType<typeof Thunder> | null>(null)

export const useGQL = () => {
  const gql = useContext(GQLContext)
  if (!gql) {
    throw new Error('useGQLContext must be used within a GQLContext.Provider')
  }
  return gql
}
