import React from 'react'
import { useFormContext } from 'react-hook-form'
import { ControlledTextarea } from '../../components/Form/Controlled/ControlledTextarea'
import { OrderUpdate } from './lib/types'

export const OrderNotes: React.FC = () => {
  const { control } = useFormContext<OrderUpdate>()

  return (
    <div>
      <div className={'p-main flex gap-8'}>
        <div className={'flex-1 flex flex-col gap-4'}>
          <ControlledTextarea
            label={'Poznámka k objednávce'}
            textAreaclassName={'h-56'}
            control={control}
            name={'note'}
          />
        </div>
        <div className={'flex-1 flex flex-col gap-4'}>
          <ControlledTextarea
            label={'Interní poznámka k objednávce'}
            textAreaclassName={'h-56'}
            control={control}
            name={'privateNote'}
          />
        </div>
      </div>
    </div>
  )
}
