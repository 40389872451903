import React, { useMemo } from 'react'
import { Btn } from './Btn'
import { useCore } from '../hooks'
import { ControlledSelect } from './Form/Controlled/ControlledSelect'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { ControlledInput } from './Form/Controlled/ControlledInput'
import { Carrier } from 'depoto-core'

export const CarrierCurrencyPayment: React.FC = () => {
  const { carriers = [], currencies, payments } = useCore()

  const { control } = useFormContext()

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control,
    name: 'paymentItems',
  })

  const addPaymentItem = () => {
    append({ payment: payments[0].id, amount: 0, isPaid: true })
  }

  const carrierOptions = useMemo(() => {
    return carriers
      .reduce<Carrier[]>((ac, el) => {
        if (ac.find(c => c.id === el.id)) {
          return ac
        }
        return [...ac, el]
      }, [])
      .map(c => ({ label: c.name, value: c.id }))
  }, [carriers])

  return (
    <div className={'flex flex-col gap-4'}>
      <div className={'box flex flex-col gap-3'}>
        <h1 className={'text-black text-base font-semibold'}>Dopravce</h1>
        <div className={'flex gap-3'}>
          <ControlledSelect className={'flex-1'} name={'carrier'} options={carrierOptions} control={control} />
        </div>
      </div>

      <div className={'box flex flex-col gap-3'}>
        <h1 className={'text-black text-base font-semibold'}>Měna</h1>
        <div className={'flex gap-3'}>
          <ControlledSelect
            className={'flex-1'}
            options={(currencies || []).map(c => ({ label: c.name, value: c.id }))}
            control={control}
            name={'currency'}
          />
        </div>
      </div>

      <div className={'box flex flex-col gap-3'}>
        <div className={'flex justify-between items-center'}>
          <h1 className={'text-black text-base font-semibold'}>Platební metoda</h1>
          <Btn
            className={'btn-outline border-light-grey'}
            children={'Přidat platební metodu'}
            title={'Přidat platební metodu'}
            icon={'plus'}
            onClick={addPaymentItem}
          />
        </div>
        {fields.map((field, index) => (
          <div className={'flex items-center gap-3'} key={`${field.id}`}>
            <ControlledSelect
              className={'flex-1'}
              options={(payments || []).map(p => ({ label: p.name, value: p.id }))}
              control={control}
              name={`paymentItems.${index}.payment`}
            />
            <div className={'self-end flex-1'}>
              <ControlledInput
                type={'number'}
                inputclassName={'text-right'}
                name={`paymentItems.${index}.amount`}
                control={control}
              />
            </div>
            <Controller
              name={`paymentItems.${index}.isPaid`}
              control={control}
              defaultValue={true}
              render={() => <></>}
            />
            <Controller name={`paymentItems.${index}.id`} control={control} render={() => <></>} />
            <div className={'self-end'}>
              <Btn
                className={'btn-danger btn-circle'}
                icon={'trash-alt'}
                title={'Smazat'}
                onClick={() => remove(index)}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
