import React, { useState, useEffect } from 'react'
import { NavLink, useHistory, useParams } from 'react-router-dom'
import { Schema } from '../lib'
import { Package, PackageDisposal } from 'depoto-core'
import { Btn, Loader, Group as GroupComponent, Dropdown, DateFormatted, EVENT_SHOW_NOTIFICATION } from '../components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCore } from '../hooks'

export const DispatchList = () => {
  // const [isFetching, setIsFetching] = useState<boolean>(false)
  const [selectedCarrier, setSelectedCarrier] = useState<string>()
  const [selectedCompany, setSelectedCompany] = useState<number>()
  const [disposals, setDisposals] = useState<PackageDisposal[]>([])
  const { core, childCompanies, company, isFetching, setIsFetching } = useCore()
  const history = useHistory()

  useEffect(() => {
    if (childCompanies.length === 0) {
      setSelectedCompany(company?.id)
    }
    getDisposals()
  }, [])

  const sendDisposal = async (disposal: PackageDisposal) => {
    setIsFetching(true)
    try {
      await core?.services.pack.sendDisposal(disposal, Schema.packageDisposal.list)
    } catch (errors) {
      document.dispatchEvent(
        new CustomEvent(EVENT_SHOW_NOTIFICATION, {
          detail: {
            message: `Chyba:\n${JSON.stringify(errors)}`,
            type: 'warning',
          },
        }),
      )
    }
    setIsFetching(false)
    getDisposals()
  }

  const getDisposals = async (carrier?: string) => {
    setIsFetching(true)
    try {
      const filters =
        !!selectedCarrier || !!carrier ? { carrier: carrier && carrier.length > 0 ? carrier : selectedCarrier } : {}
      const res = await core?.services.pack.getDisposals({ filters }, Schema.packageDisposal.detail)
      if (res.items) {
        setDisposals(res.items.map((d: any) => new PackageDisposal(d)))
      }
    } catch (errors) {
      document.dispatchEvent(
        new CustomEvent(EVENT_SHOW_NOTIFICATION, {
          detail: {
            message: `Chyba:\n${JSON.stringify(errors)}`,
            type: 'warning',
          },
        }),
      )
    }
    setIsFetching(false)
  }
  console.log({ disposals })
  return isFetching ? (
    <Loader size="fill-container" />
  ) : (
    <div className="p-3">
      <div className="flex justify-end">
        {/*TODO filters?*/}
        <NavLink
          to={`/dispatch/create`}
          className={'btn btn-primary'}
          children={`Vytvořit nový svoz`}
          title={'Vytvořit nový svoz'}
        />
      </div>
      <div className="flex flex-col">
        <div className="grid grid-cols-7 gap-1 pt-3 pb-3">
          <div className="font-bold" style={{ gridColumn: '1 / span 2' }}>
            Dopravce
          </div>
          <div className="font-bold">Kód svozu</div>
          {/* TODO: wut prijemce svozu .. ? => TL  (company name; name + lastname / where?) */}
          <div className="font-bold">Příjemce</div>
          <div className="font-bold">Datum odeslání</div>
          <div className="font-bold">Počet balíků</div>
          <div className="font-bold">PDF</div>
          <div className="font-bold"> </div>
        </div>
        {disposals.length > 0 &&
          disposals.map(d => (
            <div
              className="grid grid-cols-7 gap-1 py-2 border-b border-solid border-neutral-200"
              key={`dispatch_${d.id}`}>
              <div style={{ gridColumn: '1 / span 2' }}>{d.carrier?.name || ''}</div>
              <div>{d.code}</div>
              {d.packages?.length > 0 ? (
                <div>
                  {d.packages[0]?.order?.shippingAddress?.companyName || ''}{' '}
                  {d.packages[0]?.order?.shippingAddress?.firstName || ''}{' '}
                  {d.packages[0]?.order?.shippingAddress?.lastName || ''}
                </div>
              ) : (
                <div>&nbsp;</div>
              )}
              <div>
                <DateFormatted date={d.sent} />
              </div>
              <div>{d.packages?.length || 0}</div>
              <div>
                <a href={d.ticketUrl} className={'btn btn-primary btn-xs ml-auto'}>
                  stáhnout
                </a>
              </div>
              <div className="flex">
                {/*{!d.sent && (*/}
                {/*  <Btn*/}
                {/*    className={'btn-primary btn-xs ml-auto'}*/}
                {/*    icon={'fa-truck-loading'}*/}
                {/*    children={'Odeslat'}*/}
                {/*    title={'Odeslat'}*/}
                {/*    isDisabled={isFetching || state.isFetching}*/}
                {/*    isLoading={isFetching || state.isFetching}*/}
                {/*    onClick={() => sendDisposal(d)}*/}
                {/*  />*/}
                {/*)}*/}
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}
