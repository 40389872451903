import React, { ReactNode } from 'react'

export function SquareAmountCell({ children, className }: { children: ReactNode; className?: string }) {
  return (
    <div className={`place-self-center self-center flex justify-center flex-1`}>
      <div className={`square ${className}`}>
        <span className="square-amount mx-1 text-center">{children}</span>
      </div>
    </div>
  )
}
