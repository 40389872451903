import React from 'react'
import { Field, Fieldset, Label, Switch } from '@headlessui/react'
import { Fn } from 'depoto-core'

type Props = {
  label?: string
  value?: boolean
  onChange?: Fn
}
export const Toggle: React.FC<Props> = ({ label, value, onChange }) => {
  return (
    <Fieldset>
      <Field>
        <div className="flex items-center gap-2">
          <Switch
            checked={!!value}
            onChange={() => (typeof onChange === 'function' ? onChange(!value) : null)}
            className={`${
              !!value ? 'bg-primary-blue' : 'bg-gray-200'
            } relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2`}>
            <span
              className={`${
                !!value ? 'translate-x-6' : 'translate-x-1'
              } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
            />
          </Switch>
          {label && label.length > 0 && (
            <Label className="font-semibold text-base text-black cursor-pointer">{label}</Label>
          )}
        </div>
      </Field>
    </Fieldset>
  )
}
