import { ClearanceItem, Order } from 'depoto-core'
import { Schema } from '../../../lib'
import { DepotoCore } from 'depoto-core'
import { QueryClient } from '@tanstack/react-query'
import { AddressUpdate, OrderUpdate } from './types'
import { Thunder } from '../../../zeus'

export const mutateClearanceItem =
  ({ order, core }: { order: Order | null; core: DepotoCore }) =>
  ({ updates, type }: { updates: Partial<ClearanceItem>; type: string }) => {
    if (!order) return Promise.reject(null)
    return core.services.order.updateClearanceItemFromOrder(
      updates,
      order,
      type,
      Schema.order.detail.clearanceItems,
    ) as unknown as Promise<ClearanceItem> // Typing MUST be fixed in core
  }

export const updateClearanceItemQuery =
  ({ queryClient, order }: { queryClient: QueryClient; order: Order | null }) =>
  (data: ClearanceItem) => {
    queryClient.setQueriesData(['order', order?.id], (old: Order | undefined) => {
      if (old) {
        return {
          ...old,
          clearanceItems: old.clearanceItems.map(ci => (ci.id === data.id ? data : ci)),
        }
      } else {
        return old
      }
    })
  }

export const updateOrderMutation =
  ({ order, gql }: { order: Order | undefined; gql: ReturnType<typeof Thunder> }) =>
  async (updates: OrderUpdate) => {
    if (!order) return Promise.reject(null)

    // Additional logic for removing paymentItems (BE does support creating in updateOrder, but not deleting)
    const deletedPaymentItems = order.paymentItems.filter(item =>
      updates.paymentItems
        ? !updates.paymentItems?.find(i => {
            // @ts-ignore
            return i?.id === item.id
          })
        : false,
    )
    if (deletedPaymentItems.length > 0) {
      await Promise.all(
        deletedPaymentItems.map(item => gql('mutation')({ deletePaymentItem: [{ id: item.id }, { errors: true }] })),
      )
    }

    // Remove id from paymentItems. BE throws error if id is present, but we need id to make logic above work
    if (updates.paymentItems) {
      // @ts-ignore
      updates.paymentItems = updates.paymentItems.map(
        pi => pi && Object.fromEntries(Object.entries(pi).filter(([key]) => key !== 'id')),
      )
    }

    return gql('mutation')({
      updateOrder: [
        { id: order.id, ...updates },
        { data: { id: true }, errors: true },
      ],
    })
  }

export const updateAddressMutation =
  ({ addressId, gql }: { addressId: any; gql: ReturnType<typeof Thunder> }) =>
  async (updates: AddressUpdate) => {
    if (!addressId) {
      return (
        await gql('mutation')({
          createAddress: [
            { ...updates, country: updates.country || 'CZ' },
            { data: { id: true }, errors: true },
          ],
        })
      ).createAddress
    }

    return (
      await gql('mutation')({
        updateAddress: [
          { id: addressId, ...updates },
          { data: { id: true }, errors: true },
        ],
      })
    ).updateAddress
  }
