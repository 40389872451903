import React, { useRef } from 'react'
import { FileDrop } from 'react-file-drop'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Order, File as DepotoFile } from 'depoto-core'
import { Btn } from './Btn'
import { useCore } from '../hooks'
import { Fn } from 'depoto-core'

type Props = {
  order: Order
  onUpdate: Fn
}

export const OrderFiles: React.FC<Props> = ({ order, onUpdate }) => {
  const { core, isFetching, setIsFetching } = useCore()
  const inputRef = useRef<HTMLInputElement>(null)

  const fileToBase64 = (file: File): Promise<string | any> => {
    return new Promise((resolve, reject) => {
      if (!!file) {
        const reader = new FileReader()
        reader.readAsBinaryString(new Blob([file]))
        reader.onload = evt => {
          resolve({
            text: file.name,
            originalFilename: file.name,
            mimeType: file.type,
            base64Data: `data:application/octet-stream;base64,${btoa(String(evt?.target?.result))}`,
          })
        }
        reader.onerror = () => {
          reject('Došlo k chybě při zpracování souboru')
        }
      } else {
        reject('No file')
      }
    })
  }

  const uploadFiles = (droppedFiles: any) => {
    const files: File[] = Array.from(droppedFiles)
    const promisesBase64s = files.map(f => fileToBase64(f))
    setIsFetching(true)
    Promise.all(promisesBase64s)
      .then(resolvedBase64s => {
        const promisesUploads = resolvedBase64s.map(b => {
          return core?.services.file.create(new DepotoFile(b), undefined, order.id)
        })
        return Promise.all(promisesUploads)
      })
      .catch(e => console.warn(`uploadFiles - fileToBase64 strings err: ${e.stack}`))
      // .then((resolvedUploadedFiles: DepotoFile[] | void) => {
      .then(() => {
        onUpdate()
        setIsFetching(false)
      })
      .catch(e => console.warn(`uploadFiles - uploadFiles strings err: ${e.stack}`))
  }

  const deleteFile = async (file: DepotoFile) => {
    if (confirm('Opravdu smazat?')) {
      await core?.services.file.delete(file)
      onUpdate()
    }
  }

  const deleteAll = async () => {
    if (confirm('Opravdu smazat?')) {
      for (const file of order.files) {
        await core?.services.file.delete(file)
      }
      onUpdate()
    }
  }

  const showFile = (file: DepotoFile) => {
    window.open(file.url, '_blank')
  }

  return (
    <div className={'p-main'}>
      <FileDrop
        // onDragOver={(event) => console.log('onDragOver', event)} // todo classes
        // onDragLeave={(event) => console.log('onDragLeave', event)} // todo classes
        onDrop={(files, event) => uploadFiles(files)}>
        <div className={'box-files w-4/6'}>
          <input type={'file'} ref={inputRef} hidden={true} onChange={e => uploadFiles(e.target.files)} />
          <div className={'flex gap-2'}>
            <Btn
              className={'btn-secondary mt-2'}
              children={'Nahrát soubor'}
              title={'Nahrát soubor'}
              icon={'fa-upload'}
              isDisabled={isFetching}
              isLoading={isFetching}
              onClick={() => inputRef.current?.click()}
            />
            <Btn
              className={'btn-danger mt-2'}
              children={'Smazat vše'}
              title={'Smazat vše'}
              icon={'fa-trash'}
              isDisabled={isFetching}
              isLoading={isFetching}
              onClick={deleteAll}
            />
          </div>
          {order.files.map(file => (
            <div key={file.id} className={'grid grid-rows-1 grid-flow-row gap-4'}>
              <div className={'flex justify-between border-b pb-2'}>
                <div className={'flex items-center gap-3'}>
                  <FontAwesomeIcon icon={['far', 'file-word']} size="lg" />
                  <div className={'text-primary-blue font-semibold cursor-pointer'} onClick={() => showFile(file)}>
                    {file?.originalFilename}
                  </div>
                </div>
                <div className={'flex items-center gap-12'}>
                  <div className={'text-dark-grey font-normal'}>{`${(Number(file?.size || 0) / 1024).toFixed(
                    2,
                  )} Kb`}</div>
                  <Btn
                    className={'btn-circle btn-danger'}
                    icon={'trash-alt'}
                    title={'Smazat'}
                    onClick={() => deleteFile(file)}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </FileDrop>
    </div>
  )
}
