import { Address, Order } from 'depoto-core'
import { AddressUpdate, OrderUpdate } from './types'

type DirtyFieldsType<T> =
  | {
      [P in keyof T]?: T[P] extends object ? DirtyFieldsType<T[P]> : boolean
    }
  | boolean

export const dirtyValues = <T>(dirtyFields: DirtyFieldsType<T>, allValues: T): T | null => {
  if (dirtyFields === true || Array.isArray(dirtyFields)) {
    return allValues
  } else if (typeof dirtyFields === 'object') {
    return Object.fromEntries(
      (Object.keys(dirtyFields) as Array<keyof T>)
        .map(key => {
          const dirtyField = dirtyFields[key]
          if (dirtyField === undefined) {
            return [key, null]
          }
          return [key, dirtyValues(dirtyField as DirtyFieldsType<T[typeof key]>, allValues[key])]
        })
        .filter(([, value]) => value !== null || (Array.isArray(value) && value.length <= 0)),
    ) as T
  } else {
    return null
  }
}

export function orderToOrderUpdate(order: Partial<Order> = {}): Partial<OrderUpdate> {
  return {
    id: order?.id,
    note: order?.note,
    privateNote: order?.privateNote,
    paymentItems:
      order?.paymentItems?.map(item => ({
        id: item.id,
        payment: item.payment?.id,
        amount: item.amount.toString(),
        isPaid: typeof item.isPaid ? item.isPaid : false,
      })) || [],
    tags: order?.tags?.map(tag => tag.id) || [],
    carrier: order?.carrier?.id,
    currency: order?.currency?.id,
  }
}

export function addressToAddressUpdate(address: Partial<Address> = {}): Partial<AddressUpdate> {
  return {
    firstName: address?.firstName || '',
    lastName: address?.lastName || '',
    phone: address?.phone || '',
    email: address?.email || '',
    companyName: address?.companyName || '',
    ic: address?.ic || '',
    dic: address?.dic || '',
    street: address?.street || '',
    zip: address?.zip || '',
    city: address?.city || '',
    country: address?.country || 'CZ',
    branchId: address?.branchId || '',
  }
}
