import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Fn } from 'depoto-core'
import { downloadFileWithAuth, printDocument, printTicket, translate, wait } from '../lib'
import { useCore } from '../hooks'
import { Btn } from './Btn'
import { Dropdown } from './Dropdown'
import { NoteModal } from './NoteModal'
import { LinkExternal } from './LinkExternal'
import { useFormContext } from 'react-hook-form'
import { useOrderDetailContext } from '../views/OrderDetails/OrderDetailContext'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useGQL } from '../lib/GQLContext'
import { toast } from 'react-toastify'

// leave recieved typo (api)
type ProcessStates =
  | 'recieved'
  | 'picking'
  | 'packing'
  | 'packed'
  | 'dispatched'
  | 'delivered'
  | 'returned'
  | 'picking_error'
  | 'cancelled'
const states: ProcessStates[] = ['recieved', 'picking', 'packing', 'packed', 'dispatched', 'delivered', 'returned']
const clientUrl = 'https://app.depoto.cz'
let isProcessingChanges = false

type Props = {
  onUpdate: Fn
}
export const Footer: React.FC<Props> = ({ onUpdate }) => {
  const {
    formState: { isDirty },
  } = useFormContext()
  const { order: currentOrder } = useOrderDetailContext()
  const queryClient = useQueryClient()
  const gql = useGQL()

  const [pdfDocs, setPdfDocs] = useState<any[]>([])
  const refNoteModal = useRef<NoteModal>(null)
  const refErrorNoteModal = useRef<NoteModal>(null)
  const history = useHistory()
  const { core, setCurrentOrder, isFetching, autoProcessStatusChange } = useCore()
  const currentProcessStatus: ProcessStates = (currentOrder?.processStatus?.id as ProcessStates) || 'recieved'
  const unpicked = currentOrder?.clearanceItems?.filter(ci => ci.picked !== ci.amount) || []
  const unpacked = currentOrder?.clearanceItems?.filter(ci => ci.packed !== ci.amount) || []
  const canUpdateProcessStatus = !(
    (currentProcessStatus === 'picking' && unpicked && unpicked.length > 0) ||
    (currentProcessStatus === 'packing' && unpacked && unpacked.length > 0) ||
    (currentProcessStatus === 'packing' && currentOrder?.carrier?.id.length === 0)
  )
  const btnNextStatusLabel =
    {
      recieved: 'Předat k vyskladnění',
      picking: 'Předat k zabalení',
      packing: 'Zabaleno',
      packed: 'Předat dopravci',
      dispatched: 'Doručeno',
    }[currentProcessStatus] || 'Vráceno'

  const backSteps = (() => {
    if (!currentProcessStatus) return []

    const earlyStates = ['recieved', 'picking', 'packing']
    const lateStates = ['packed', 'dispatched', 'delivered', 'cancelled']

    if (earlyStates.includes(currentProcessStatus)) {
      return states.slice(0, states.indexOf(currentProcessStatus))
    }

    if (lateStates.includes(currentProcessStatus)) {
      return states.slice(3, states.indexOf(currentProcessStatus))
    }

    return []
  })()

  const getNextProcessStatus = (currentStatus?: ProcessStates): ProcessStates => {
    const currentIndex = currentStatus ? states.indexOf(currentStatus) : 0
    if (currentIndex === states.length - 1) {
      return states[states.length - 1]
    }
    return states[currentIndex + 1]
  }

  const { mutate: mutateProcessStatus, isLoading } = useMutation({
    mutationFn: ({ nextStatus, note }: { nextStatus: ProcessStates; note?: string }) =>
      toast.promise(
        gql('mutation')({
          updateOrder: [
            {
              id: currentOrder?.id,
              processStatusRelation: { status: nextStatus, ...(note ? { note } : {}) },
            },
            {
              errors: true,
            },
          ],
        }),
        {
          pending: 'Aktualizuji stav objednávky...',
          success: 'Stav objednávky aktualizován!',
          error: 'Chyba při aktualizaci stavu objednávky!',
        },
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['order', currentOrder?.id] })
      queryClient.invalidateQueries({ queryKey: ['orders'] })
      history.push(`/dashboard/${currentProcessStatus}`)
    },
  })

  const updateProcessStatus = async (nextStatus: ProcessStates, note?: string) => {
    if (nextStatus === 'packing') {
      if (unpicked && unpicked.length > 0) {
        alert('Chyba: nenapickovane polozky!')
        return
      }
    }
    if (nextStatus === 'packed' && unpacked && unpacked.length > 0) {
      alert('Chyba: nezabalene polozky!')
      return
    }
    let hasErrors = false
    if (nextStatus === 'packed') {
      const packs = currentOrder?.packages || []
      for (const p of packs) {
        if (!p.sent) {
          const resSendPackage = await core?.services.pack.send(p)
          console.log(resSendPackage)
        }
        const res = await printTicket(p)

        if (
          (res?.stderr?.length > 0 && res?.stderr.includes('error')) ||
          (res?.error?.length > 0 && res?.error.includes('error'))
        ) {
          hasErrors = true
        }
        await wait()
      }
      await wait(250)
    }
    mutateProcessStatus({ nextStatus, note })
    // todo test in app
    if (hasErrors) {
      alert('Chyba při tisku štítků')
      return
    }
  }

  const cancelReservation = async () => {
    if (confirm('Opravdu zrusit rezervaci?')) {
      await core?.services.order.deleteReservation(currentOrder)
      onUpdate()
      history.push(`/dashboard/${currentProcessStatus}`)
    }
  }

  const promptErrorNote = () => {
    refErrorNoteModal?.current?.openModal()
  }

  const showOrderDetailInClient = () => {
    console.log(currentOrder?.id)
  }

  useEffect(() => {
    const possiblePdfUrls = []
    if (currentOrder?.reservationUrl?.length) {
      possiblePdfUrls.push({ id: 'reservationUrl', name: 'objednávka' })
    }
    if (
      currentOrder?.invoiceUrl?.length &&
      (currentProcessStatus === 'packed' || currentProcessStatus === 'dispatched')
    ) {
      possiblePdfUrls.push({ id: 'invoiceUrl', name: 'faktura' })
    }
    if (
      currentOrder?.movePacks?.[0]?.pdfWOPricesUrl?.length &&
      (currentProcessStatus === 'packed' || currentProcessStatus === 'dispatched')
    ) {
      possiblePdfUrls.push({ id: 'movePack', name: 'dodací list' })
    }
    setPdfDocs(possiblePdfUrls)
    if (!autoProcessStatusChange) {
      return
    }
    const checkErrorsBeforeInitingPackages = () => {
      let warning = 'Chyba pri zakladani baliku:\n\n'
      let isError = false
      if (!currentOrder?.carrier) {
        warning += 'Není nastaven dopravce! \n\n'
        isError = true
      }
      if (currentOrder?.carrier?.requiredShippingAddress && !currentOrder?.shippingAddress) {
        warning += 'Není nastavena doručovací adresa! \n\n'
        isError = true
      }
      if (isError) {
        warning += 'Doplňte informace a pokračujte manuálním posunem stavu'
        alert(warning)
      }
      return isError
    }
    if (!isProcessingChanges) {
      if (currentProcessStatus === 'picking' && !unpicked?.length) {
        isProcessingChanges = true // ugly hack prevents multiple firing of same event
        const hasErrors = checkErrorsBeforeInitingPackages()
        setTimeout(() => (isProcessingChanges = false), hasErrors ? 5000 : 300)
        if (!hasErrors) {
          updateProcessStatus('packing')
        }
      }
      if (currentProcessStatus === 'packing' && !unpacked?.length) {
        isProcessingChanges = true // ugly hack prevents multiple firing of same event
        setTimeout(() => (isProcessingChanges = false), 300)
        updateProcessStatus('packed')
      }
    }
  }, [currentOrder])

  const printPdf = (urlType: 'invoiceUrl' | 'reservationUrl' | 'movePack') => {
    // console.log(currentOrder?.[urlType])
    if (urlType === 'movePack') {
      downloadFileWithAuth(currentOrder!.movePacks?.[0]?.pdfWOPricesUrl, 'movePack.pdf', core)
      // printDocument(currentOrder!.movePacks?.[0]?.pdfWOPricesUrl, false, false)
    } else if (currentOrder?.[urlType]) {
      printDocument(currentOrder[urlType], false, false)
    }
  }

  return (
    <footer className={'p-main bg-white border-t border-b border-cool-grey flex justify-between items-center'}>
      <div className={'flex gap-2 items-center'}>
        {!!currentOrder?.id ? (
          <LinkExternal url={`${clientUrl}/order/${currentOrder.id}`} label={`Otevřít v klientu`} />
        ) : null}
        <div className={'ml-28'}>
          <Dropdown
            title={'Tisk pdf'}
            hasAllOption={false}
            options={pdfDocs}
            onChange={val => printPdf(val)}
            isDownDirection={true}
          />
        </div>
        {/*<Btn*/}
        {/*  onClick={() => updateOrder()}*/}
        {/*  isDisabled={isFetching || !orderUpdates}*/}
        {/*  isLoading={isFetching}*/}
        {/*  className={'btn-outline btn-primary'}*/}
        {/*  children={'Uložit'}*/}
        {/*  title={'Uložit'}*/}
        {/*  icon={'check'}*/}
        {/*/>*/}
      </div>
      <NoteModal note={''} ref={refNoteModal} onUpdate={note => updateProcessStatus('packing', note)} />
      <NoteModal
        note={''}
        isError={true}
        ref={refErrorNoteModal}
        onUpdate={note => updateProcessStatus('picking_error', note)}
      />
      <div className={'flex gap-2 items-center'}>
        {backSteps.length > 0 && (
          <Dropdown
            title={'Vrátit stav'}
            selectedId={currentProcessStatus}
            options={backSteps.map(s => ({ id: s, name: translate(s) }))}
            hasAllOption={false}
            onChange={val => updateProcessStatus(val)}
            isDownDirection={true}
          />
        )}
        <Btn
          onClick={promptErrorNote}
          isDisabled={isLoading}
          isLoading={isLoading}
          className={'btn-outline-red border-danger'}
          icon={'triangle-exclamation'}
          children={'Nahlásit chybu'}
          title={'Nahlásit chybu'}
        />
        <Btn
          onClick={cancelReservation}
          isDisabled={isLoading}
          isLoading={isLoading}
          className={'btn-danger'}
          icon={'trash-alt'}
          children={'Zrušit rezervaci'}
          title={'Zrušit rezervaci'}
        />
        <Btn
          onClick={() => updateProcessStatus(getNextProcessStatus(currentProcessStatus))}
          isDisabled={isLoading || !canUpdateProcessStatus}
          isLoading={isLoading}
          className={'btn-primary'}
          icon={'check'}
          children={btnNextStatusLabel}
          title={btnNextStatusLabel}
        />
      </div>
    </footer>
  )
}
