/// <reference types="vite-plugin-svgr/client" />
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './config/icons'
import * as Sentry from '@sentry/react'

Sentry.init({
  dsn: 'https://b893c3d47651eda4a9d1cb2db9df19c3@o780420.ingest.sentry.io/4506626409496576',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost',
        /^https:\/\/expedice\.depoto\.cz/,
        /^https:\/\/expedice\.depoto\.cz\.tomatomstage\.cz/,
      ],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  enabled: process.env.NODE_ENV === 'production',
  environment: process.env.NODE_ENV || 'development',
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

// // todo dependency hotfix for cleaner dev console
// ;(() => {
//   const oldLogError = console.error
//   console.error = function (...args) {
//     if (
//       typeof args[0] !== 'string' ||
//       !(
//         args[0].includes('is deprecated in StrictMode') &&
//         args[1].includes('findDOMNode') &&
//         args[3].includes('Transition')
//       )
//     ) {
//       oldLogError.apply(console, args)
//     }
//   }
// })()

// todo vaha: na window._depoto.spawn proces, cekat na data, na packageView zobrazit modal pokud vic baliku s tim, ke kteremu priradit vahu. pokud jeden, vyplnit a poslat updatePackage
