import clsx from 'clsx'
import * as React from 'react'

export interface CompactInputProps extends React.ComponentProps<'input'> {
  right?: React.ReactNode
  label?: string
}
const CompactInput = React.forwardRef<HTMLInputElement, CompactInputProps>(
  ({ className, type, right, label, ...props }, ref) => {
    return (
      <div className="relative">
        {label && (
          <label className="absolute z-1 -top-2 left-3 px-1 bg-white text-xs text-shadow-grey font-medium">
            {label}
          </label>
        )}
        <div
          className={clsx(
            'flex items-center justify-center gap-2 flex h-10 rounded-md border border-input bg-background text-base ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 md:text-sm',
            className,
            right && 'pr-2',
          )}>
          <input type={type} className={clsx('w-full pl-3 py-2 bg-transparent outline-none')} ref={ref} {...props} />
          <div className={'text-shadow-grey font-medium self-end mb-1'}>{right}</div>
        </div>
      </div>
    )
  },
)
CompactInput.displayName = 'CompactInput'

export { CompactInput }
