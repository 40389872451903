import React, { useState } from 'react'
import { Fn } from 'depoto-core'
import { generateUuidV4 } from 'depoto-core'

export interface FormSelectProps {
  className?: string
  label?: string
  value?: any
  options?: Array<{ label: string; value: string | number | any }>
  onChange: Fn
}

export const FormSelect: React.FC<FormSelectProps> = ({ className, label, value, options, onChange }) => {
  const selectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const val = event.target.value
    onChange(val)
  }

  return (
    <div className={className === undefined ? 'form-control' : className + ' form-control'}>
      <label className={label === undefined ? 'none' : ''}>{label}</label>
      <select value={value} onChange={selectChange} className={'select'}>
        <option disabled key={`select-${label || generateUuidV4()}-default`}>
          Zvolit možnost
        </option>
        {options &&
          options?.length > 0 &&
          options.map(o => (
            <option value={o.value} key={`select-${o.value || generateUuidV4()}`}>
              {o.label}
            </option>
          ))}
      </select>
    </div>
  )
}
