import { useContext, useEffect, useMemo } from 'react'
import { KeyEventType, wait } from '../../../../lib'
import { OrderDetailContext } from '../../OrderDetailContext'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useCore } from '../../../../hooks'
import { mutateClearanceItem, updateClearanceItemQuery } from '../mutation'

export const useOrderPicking = () => {
  const { order } = useContext(OrderDetailContext)
  const { core } = useCore()
  const queryClient = useQueryClient()

  const { mutate } = useMutation({
    mutationFn: useMemo(() => mutateClearanceItem({ order, core }), [order, core]),
    onSuccess: useMemo(() => updateClearanceItemQuery({ queryClient, order }), [order]),
  })

  useEffect(() => {
    if (!order) return

    const processCode = async (c: CustomEvent | any) => {
      const clearanceItems = order.clearanceItems.filter(
        ci => ci.product?.code === c?.detail || ci.product?.ean === c?.detail,
      )
      const clearanceItem = clearanceItems?.length > 0 ? clearanceItems[0] : null
      console.log({ clearanceItem })
      if (clearanceItem) {
        console.log(order.processStatus?.id)
        const updates: any = { id: clearanceItem.id }
        if (order.processStatus?.id === 'picking') {
          const nextPicked = clearanceItem.picked === clearanceItem.amount ? 0 : clearanceItem.amount
          if (nextPicked > 1 && !confirm(`Máte všechny kusy (${nextPicked}) ?`)) {
            return
          }
          mutate({ updates: { id: clearanceItem.id, picked: nextPicked }, type: 'picked' })
        }
        if (order.processStatus?.id === 'packing') {
          const nextPacked = clearanceItem.packed === clearanceItem.amount ? 0 : clearanceItem.amount
          console.log(nextPacked, confirm(`Máte všechny kusy (${nextPacked}) ?`))
          if (nextPacked > 1 && !confirm(`Máte všechny kusy (${nextPacked}) ?`)) {
            return
          }
          mutate({ updates: { id: clearanceItem.id, packed: nextPacked }, type: 'packed' })
        }
      }
      await wait(600)
    }

    document.addEventListener(KeyEventType.BARCODE, processCode)
    return () => {
      document.removeEventListener(KeyEventType.BARCODE, processCode)
    }
  }, [order])
}
