import React from 'react'
import { useCore } from '../hooks'
import { ControlledInput } from './Form/Controlled/ControlledInput'
import { UseFormReturn } from 'react-hook-form'
import { ControlledSelect } from './Form/Controlled/ControlledSelect'
import { AddressUpdate } from '../views/OrderDetails/lib/types'

type Props = {
  form: UseFormReturn<AddressUpdate>
}

export const Address: React.FC<Props> = ({ form }) => {
  const { countries } = useCore()
  const { control } = form
  const countriesToSelect = (countries || []).map(c => ({ label: c.name, value: c.id }))

  return (
    <div className="box">
      <div className={'grid grid-cols-4 gap-2'}>
        <ControlledInput
          className={'col-span-2'}
          label={'Jméno'}
          name={'firstName'}
          control={control}
          rules={{ required: 'Jméno je povinne' }}
        />
        <ControlledInput
          className={'col-span-2'}
          label={'Příjmení'}
          name={'lastName'}
          control={control}
          rules={{ required: 'Příjmení je povinne' }}
        />
        <ControlledInput className={'col-span-2'} label={'Telefon'} name={'phone'} control={control} />
        <ControlledInput className={'col-span-2'} label={'E-mail'} name={'email'} control={control} />
        <ControlledInput className={'col-span-4'} label={'Společnost'} name={'companyName'} control={control} />
        <ControlledInput className={'col-span-2'} label={'IČ'} name={'ic'} control={control} />
        <ControlledInput className={'col-span-2'} label={'DIČ'} name={'dic'} control={control} />
        <ControlledInput className={'col-span-4'} label={'Ulice a č.p.'} name={'street'} control={control} />
        <ControlledInput className={'col-span-1'} label={'PSČ'} name={'zip'} control={control} />
        <ControlledInput className={'col-span-3'} label={'Město'} name={'city'} control={control} />
        <ControlledSelect
          className={'col-span-1'}
          label={'Země'}
          options={countriesToSelect}
          name={'country'}
          control={control}
        />
        <ControlledInput className={'col-span-3'} label={'ID pobočky'} name={'branchId'} control={control} />
      </div>
    </div>
  )
}
