import React, { useState } from 'react'
import { Address, CarrierCurrencyPayment } from '../../components'
import { Tag } from 'depoto-core'
import { Schema } from '../../lib'
import { useCore } from '../../hooks'
import { Controller, useFormContext, UseFormReturn } from 'react-hook-form'
import { AddressUpdate, OrderUpdate } from './lib/types'
import AsyncSelect from 'react-select/async'

type SelectOption = {
  label: string
  value: string | number
}

type Props = {
  form: UseFormReturn<AddressUpdate>
}

export const OrderShipping: React.FC<Props> = ({ form }) => {
  const { core } = useCore()

  const { control } = useFormContext<OrderUpdate>()

  // Existing of this very state is VERY stupid, probably a better solution would be to have tags in the global state
  // loaded on app start, like in depoto-terminal, so TODO please
  // + why the fuck react-select doesn't have option to provide value as VALUE (like only tag id in this case, not the
  // full object with label), but maybe it's ok, idk
  const [tags, setTags] = useState<SelectOption[]>([])

  const filterTags = (val: string, options: Tag[]): SelectOption[] => {
    const tags: Tag[] = options.filter((tag: Tag) => tag.name.toLowerCase().includes(val.toLowerCase()))
    return tags.map(tag => ({ value: tag.id, label: tag.name }))
  }

  const promiseOptions = (inputValue: string): Promise<Array<SelectOption>> =>
    new Promise<SelectOption[]>(resolve => {
      core.services.tag.getList({ filters: { name: inputValue } }, Schema.basic.list).then((res: any) => {
        if (res?.items?.length > 0) {
          setTags(filterTags(inputValue, res.items))
          resolve(filterTags(inputValue, res.items))
        }
      })
    })

  return (
    <div className={'p-main flex gap-8'}>
      <div className={'flex-1'}>
        <CarrierCurrencyPayment />
        <div>
          <div className={'box flex flex-col gap-3 mt-5'}>
            <h1 className={'text-black text-base font-semibold'}>Tagy</h1>
            <div className={'flex-1'}>
              <Controller
                control={control}
                name={'tags'}
                render={({ field: { onChange, value } }) => (
                  <AsyncSelect
                    isMulti
                    cacheOptions
                    defaultOptions={true}
                    value={(value as number[])
                      .map(v => ({ value: v, label: tags.find(t => t.value === v)?.label }))
                      .sort((a, b) => a.value - b.value)}
                    loadOptions={promiseOptions as any}
                    onChange={selected => {
                      onChange(selected.map(el => (el as { value: number }).value))
                    }}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={'flex-1'}>
        <Address form={form} />
      </div>
    </div>
  )
}
