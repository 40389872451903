import React, { useMemo } from 'react'
import { Order, OrderItem } from 'depoto-core'
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { ProductCell } from './components/ProductCell'
import { Table } from './components/Table'
import { SquareAmountCell } from './components/SquareAmountCell'
import { EditOrderItemModal } from '../../components/EditOrderItemModal'

type Props = {
  items: OrderItem[]

  // Packaging flow
  packaging?: boolean
  renderActions?: (item: OrderItem) => React.ReactNode
  actionsWidth?: number
  movingOrderItemId?: number
}

const columnHelper = createColumnHelper<OrderItem>()

export const OrderItems: React.FC<Props> = ({ items, packaging, renderActions, actionsWidth, movingOrderItemId }) => {
  const orderItems: OrderItem[] = items
  const Columns = useMemo(
    () => [
      columnHelper.accessor('product', {
        header: 'Zboží',
        cell: ({ getValue, row }) => <ProductCell product={{ ...getValue(), name: row.original.name }} />,
        size: 400,
      }),
      columnHelper.accessor('ean', { header: 'EAN', size: 130 }),
      columnHelper.accessor('code', { header: 'Kód' }),
      columnHelper.accessor('note', {
        header: 'Poznámka',
        cell: ({ getValue }) => (
          <span className="font-bold text-ellipsis overflow-hidden line-clamp-3">{getValue()}</span>
        ),
      }),
      columnHelper.accessor('quantity', {
        header: () => <div className={'text-center'}>Počet ks</div>,
        cell: ({ getValue }) => <SquareAmountCell>{getValue()}</SquareAmountCell>,
        size: 100,
      }),
      columnHelper.accessor('quantityUnavailable', {
        header: () => <div className={'text-center'}>Chybí ks</div>,
        cell: ({ getValue }) =>
          getValue() > 0 ? <SquareAmountCell className="square-red">{getValue()}</SquareAmountCell> : null,
        size: 100,
      }),
      columnHelper.display({
        id: 'actions',
        cell: props =>
          renderActions ? (
            <span className="flex flex-1 justify-end gap-2">
              {renderActions(props.row.original)}
              <EditOrderItemModal orderItem={props.row.original} />
            </span>
          ) : (
            <span className="flex flex-1 justify-end gap-2">
              <EditOrderItemModal orderItem={props.row.original} />
            </span>
          ),
        size: actionsWidth || 100,
      }),
    ],
    [items],
  )

  const table = useReactTable({
    columns: Columns,
    data: orderItems,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility: {
        'product.ean': !orderItems.every(el => !el?.product?.ean),
        'product.code': !orderItems.every(el => !el?.product?.code),
        position: !orderItems.every(el => el?.position == '--'),
        quantityUnavailable: !orderItems.every(el => el?.quantityUnavailable == 0),
        note: !orderItems.every(el => !el?.note),
      },
    },
    defaultColumn: {
      // @ts-ignore
      size: '1fr',
    },
  })

  return (
    <Table table={table} getClassnameForRow={row => (row.original.id === movingOrderItemId ? 'bg-amber-200' : '')} />
  )
}
