import React, { useEffect, useMemo } from 'react'
import { Route, Switch, useLocation, useParams, useRouteMatch } from 'react-router-dom'
import { Footer, Loader, OrderNotes, OrderProducts, OrderShipping, OrderState } from '../../components'
import { Order } from 'depoto-core'
import { Schema } from '../../lib'
import { OrderFiles } from '../../components/OrderFiles'
import { NotFound404 } from '../NotFound404'
import { useCore } from '../../hooks'
import { useMutation, useQuery } from '@tanstack/react-query'
import { OrderDetailContext, OrderDetailContextType } from './OrderDetailContext'
import { FormProvider, useForm } from 'react-hook-form'
import { AddressUpdate, OrderUpdate } from './lib/types'
import { Header } from './Header'
import { updateAddressMutation, updateOrderMutation } from './lib/mutation'
import { addressToAddressUpdate, dirtyValues, orderToOrderUpdate } from './lib/utils'
import { useGQL } from '../../lib/GQLContext'
import { toast } from 'react-toastify'
import SerialNumbersModal, { useSerialNumbersModal } from '../../components/SerialNumbersModal'

export const OrderDetail: React.FC<any> = () => {
  const { core, setCurrentOrder } = useCore()
  const { orderId }: any = useParams()
  const location: any = useLocation()
  const { path } = useRouteMatch()
  const gql = useGQL()

  const { serialNumbersModalProps, open, close } = useSerialNumbersModal()

  const {
    refetch,
    isLoading,
    isError,
    data: order,
  } = useQuery<Order>(
    ['order', parseInt(orderId)],
    async ({ queryKey }) => {
      const [, orderId] = queryKey
      if (orderId) {
        const response = new Order(await core?.services.order.getById(orderId as number, Schema.order.detail))
        if (!response || response?.id <= 0) {
          throw new Error('Order not found')
        }
        return response
      } else {
        throw new Error('Order not found')
      }
    },
    {
      enabled: true,
      retry: 1,
      refetchOnWindowFocus: true,
      onSuccess: data => {
        setCurrentOrder(data)
      },
    },
  )

  const orderForm = useForm<OrderUpdate>({
    defaultValues: orderToOrderUpdate(order),
  })

  // We need to use separate form for address cause it using another update method and schema
  const addressForm = useForm<AddressUpdate>({
    defaultValues: addressToAddressUpdate(order?.shippingAddress),
  })

  const { mutateAsync: saveOrder, error } = useMutation({
    mutationFn: updateOrderMutation({ order, gql }),
    onSuccess: () => refetch(),
  })

  const { mutateAsync: saveAddress } = useMutation({
    mutationFn: updateAddressMutation({ addressId: order?.shippingAddress?.id || null, gql }),
    onSuccess: data => {
      // If we created new address we need to save it to order
      if (data?.data?.id && !order?.shippingAddress?.id) {
        toast
          .promise(saveOrder({ shippingAddress: data?.data?.id }), {
            pending: 'Ukládám objednávku...',
            success: 'Objednávka uložena',
            error: 'Chyba při ukládání objednávky',
          })
          .then(() => refetch())
          .catch(e => console.error(e))
      }
    },
  })

  const handleSaveOrder = orderForm.handleSubmit(async data => {
    let dirty = { ...orderForm.formState.dirtyFields }

    if (Array.isArray(data.paymentItems) && data.paymentItems.length !== order?.paymentItems.length) {
      // @ts-expect-error: We want to mark all payment items as dirty when user created or deleted one of them
      dirty.paymentItems = true
    }

    toast
      // @ts-ignore I want to cry when I think about how to fix this
      .promise(saveOrder(dirtyValues(dirty, data)), {
        pending: 'Ukládám objednávku...',
        success: 'Objednávka uložena',
        error: {
          render({ data }) {
            return (
              <span>
                Chyba při ukládání objednávky: <span className={'text-red-500'}>{data}</span>
              </span>
            )
          },
        },
      })
      .catch(e => console.error(e))

    return
  })

  const handleSaveAddress = addressForm.handleSubmit(async data => {
    toast
      .promise(
        // @ts-ignore (same ^)
        saveAddress(dirtyValues(addressForm.formState.dirtyFields, data)).then(() => handleSaveOrder()),
        {
          pending: 'Ukládám adresu...',
          success: 'Adresa uložena',
          error: {
            render({ data }) {
              return (
                <span>
                  Chyba při ukládání adresy: <span className={'text-red-500'}>{data}</span>
                </span>
              )
            },
          },
        },
      )
      .catch(e => console.error(e))
  })

  // Reset form when order is loaded
  useEffect(() => {
    if (order && !isLoading) {
      orderForm.reset(orderToOrderUpdate(order), { keepDirty: false })
      addressForm.reset(addressToAddressUpdate(order.shippingAddress), { keepDirty: false })
    }
  }, [order])

  const handleSaveDirty = () => {
    if (addressForm.formState.isDirty) {
      handleSaveAddress().catch(e => console.error(e))
    } else if (orderForm.formState.isDirty) {
      handleSaveOrder().catch(e => console.error(e))
    }
  }

  if (isLoading) return <Loader size="fill-container" />

  if (isError || !order) return <NotFound404 />

  return (
    <OrderDetailContext.Provider value={{ order }}>
      <FormProvider {...orderForm}>
        <div className={'h-[100vh] flex flex-col'}>
          {/* <button onClick={open}>Open</button> */}
          <Header onSave={handleSaveDirty} isDirty={orderForm.formState.isDirty || addressForm.formState.isDirty} />
          <div className={'flex flex-col flex-1 justify-between overflow-y-auto'}>
            <Switch location={location}>
              <Route exact path={path}>
                <OrderProducts order={order} onUpdate={refetch} />
              </Route>
              <Route path={`${path}/items`}>
                <OrderProducts order={order} onUpdate={refetch} />
              </Route>
              <Route path={`${path}/shipping`}>
                <OrderShipping form={addressForm} />
              </Route>
              <Route path={`${path}/notes`}>
                <OrderNotes />
              </Route>
              <Route path={`${path}/states`}>
                <OrderState order={order} />
              </Route>
              <Route path={`${path}/files`}>
                <OrderFiles order={order} onUpdate={refetch} />
              </Route>
            </Switch>
          </div>
          <Footer onUpdate={refetch} />
        </div>
      </FormProvider>
      <SerialNumbersModal {...serialNumbersModalProps} />
    </OrderDetailContext.Provider>
  )
}
