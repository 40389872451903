import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react'
import { QueryFunctionContext, QueryKey, useInfiniteQuery } from '@tanstack/react-query'

// !+!+!+!+!+!+!+!+!+!+
// Module was moved from RN Terminal 
// Please do not remove unknown and "unused" functions.
// !+!+!+!+!+!+!+!+!+!+

export type CommonPaginator = {
  current: number
  endPage: number
  totalCount: number
}

export type CommonItem = {
  id: any
}

type Params<T extends CommonItem> = {
  mergeItems?: (newItems: T[], oldItems: T[]) => T[]
  fetchItems: (page: number) => Promise<{ items: T[]; paginator: CommonPaginator }>
  clientFilter?: (items: T) => boolean

  // So, why we need to load a minimum number of items?
  // It's because we have an option to use a local filter, and sometimes the local filter will remove all items
  // or left 1-2 items, and FlatList will not trigger the `onEndReached` event, so user will be stuck with only 1-2 items.
  // If we will be forced to load a minimum number of items, we can avoid this problem.
  itemsCount?: number

  queryKey: QueryKey
}

type HookReturn<T extends CommonItem> = {
  loading: boolean
  refresh: () => void
  loadMore: () => void
  isLastPage: boolean
  items: T[]
  totalCount: number
}

type CommonResponse<T> = { items: T[]; paginator: CommonPaginator }

export function usePaginated<T extends CommonItem>({ clientFilter, fetchItems, queryKey }: Params<T>): HookReturn<T> {
  const getItems = useCallback(
    async ({ queryKey, pageParam = 1, signal, meta }: QueryFunctionContext): Promise<CommonResponse<T>> => {
      const { items, paginator } = await fetchItems(pageParam)
      return { items: items.filter(clientFilter || (() => true)), paginator }
    },
    [clientFilter, fetchItems],
  )

  const { isFetching, hasNextPage, fetchNextPage, refetch, data } = useInfiniteQuery<{
    items: T[]
    paginator: CommonPaginator
  }>(queryKey, getItems, {
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.paginator.current < lastPage.paginator.endPage) {
        return lastPage.paginator.current + 1
      }
    },
  })

  const items = data ? data.pages.flatMap(page => page.items) : []
  const totalCount = data ? data?.pages?.[0]?.paginator?.totalCount : 0

  return {
    isLastPage: !hasNextPage,
    loadMore: fetchNextPage,
    loading: isFetching,
    refresh: refetch,
    items,
    totalCount,
  }
}
