import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import DtLogo from '../assets/img/App-Icon.svg?react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, NavLink, useHistory, useLocation, useParams } from 'react-router-dom'
import { ProcessStatusIcon } from './ProcessStatusIcon'
import { getHeaderTitle } from '../lib'
import { Header } from './Header'
import { useCore, useLogin } from '../hooks'
import { useIsFetching, useQuery, useQueryClient } from '@tanstack/react-query'
import { Order } from 'depoto-core'

type Props = {
  children: Element[] | ReactElement | any
  hasMainHeader: boolean
  isSettingsView?: boolean
}

export const SideMenu: React.FC<Props> = ({ children, hasMainHeader, isSettingsView }) => {
  const history = useHistory()
  const { isLoggedIn, handleLogout } = useLogin()
  // const { carrierId, checkoutId, countryId, depotId, processStatus }: any = useParams()
  // const { carrierId, checkoutId, countryId, depotId }: any = useSearchParams()
  const location = useLocation()
  const { pathname } = location
  const { core, currentOrder, setSearchString } = useCore()
  const [splitLocation, setSplitLocation] = useState<string[]>(pathname.split('/'))
  const [headerTitle, setHeaderTitle] = useState<string>(getHeaderTitle({ splitLocation, currentOrder }))
  // const splitLocation = pathname.split('/')
  // const headerTitle = getHeaderTitle({ splitLocation, currentOrder })
  const serverType = core.services.oauth.session.clientType
  const desktopApp = window._depoto
  const routeParams = window.location.search
  // let routeParams = `/${carrierId || 'all'}/${checkoutId || 'all'}/${countryId || 'all'}/${depotId || 'all'}`
  // if (routeParams === '/all/all/all/all') {
  //   routeParams = ''
  // }

  const queryClient = useQueryClient()

  const currentOrderId = useMemo(() => {
    const path = pathname.split('/')
    if (path[1] === 'order') {
      return path[path.length - 1]
    }
    return null
  }, [pathname])

  const isOrderLoading = useIsFetching({ queryKey: ['order', parseInt(currentOrderId)] })

  const orderQuery = useMemo(
    () => queryClient.getQueryData(['order', parseInt(currentOrderId)]) as Order,
    [currentOrderId, isOrderLoading],
  )
  const currentOrderProcessStatus = orderQuery?.processStatus?.id

  useEffect(() => {
    const splitLoc = pathname.split('/')
    setSplitLocation(splitLoc)
    setHeaderTitle(getHeaderTitle({ splitLocation: splitLoc, currentOrder }))
  }, [pathname, currentOrder])

  if (pathname === '/login') {
    return children
  }
  return (
    <div className="relative min-h-screen flex">
      {/*mobile menu bar*/}

      {/*sidebar*/}
      <div className={'w-20'}>
        <aside className="w-20 h-full fixed flex flex-col gap-5 py-5 bg-base-blue text-primary-blue shadow-inner scrollable-content">
          {/*logo*/}
          <Link to={'/dashboard'} className="flex justify-center items-center">
            <DtLogo width="35" />
          </Link>
          {/*nav*/}
          <nav className="flex flex-col flex-1">
            <nav className="mb-auto text-center" title={'Typ serveru'}>
              {serverType === 'prod' ? (
                <div></div>
              ) : serverType === 'stage' ? (
                <div className={'bg-danger text-white font-bold'}>[STAGE]</div>
              ) : serverType === 'dev' ? (
                <div className={'bg-danger text-white font-bold'}>[DEV]</div>
              ) : (
                ''
              )}
              {/*<FontAwesomeIcon*/}
              {/*  icon={['far', 'user']}*/}
              {/*  size="lg"*/}
              {/*  className="cursor-pointer text-light-blue hover:text-primary-blue transform"*/}
              {/*/>*/}
            </nav>

            <nav className="flex flex-col gap-10 items-center menuItems">
              <ul className={'w-full flex flex-col gap-3'}>
                <NavLink
                  to={`/dashboard/received${routeParams}`}
                  className={
                    (currentOrderProcessStatus || headerTitle) === 'received'
                      ? 'menuItem-active h-14 flex flex-col justify-center'
                      : 'h-14 flex flex-col justify-center'
                  }>
                  <li className={'flex justify-center'} onClick={() => setSearchString('')}>
                    <span className="flex justify-center items-center text-light-blue hover:text-primary-blue cursor-pointer transition-colors">
                      <span title={'Přijaté objednávky'}>
                        <ProcessStatusIcon status={'received'} size="lg" />
                      </span>
                    </span>
                  </li>
                </NavLink>
                <NavLink
                  to={`/dashboard/picking${routeParams}`}
                  className={
                    (currentOrderProcessStatus || headerTitle) === 'picking'
                      ? 'menuItem-active h-14 flex flex-col justify-center'
                      : 'h-14 flex flex-col justify-center'
                  }>
                  <li className={'flex justify-center'} onClick={() => setSearchString('')}>
                    <span className="flex justify-center items-center text-light-blue hover:text-primary-blue cursor-pointer transition-colors">
                      <span title={'Vyskladnění'}>
                        <ProcessStatusIcon status={'picking'} size="lg" />
                      </span>
                    </span>
                  </li>
                </NavLink>
                <NavLink
                  to={`/dashboard/packing${routeParams}`}
                  className={
                    (currentOrderProcessStatus || headerTitle) === 'packing'
                      ? 'menuItem-active h-14 flex flex-col justify-center'
                      : 'h-14 flex flex-col justify-center'
                  }>
                  <li className={'flex justify-center'} onClick={() => setSearchString('')}>
                    <span className="flex justify-center items-center text-light-blue hover:text-primary-blue cursor-pointer transition-colors">
                      <span title={'Balení'}>
                        <ProcessStatusIcon status={'packing'} size="lg" />
                      </span>
                    </span>
                  </li>
                </NavLink>
                <NavLink
                  to={`/dashboard/packed${routeParams}`}
                  className={
                    (currentOrderProcessStatus || headerTitle) === 'packed'
                      ? 'menuItem-active h-14 flex flex-col justify-center'
                      : 'h-14 flex flex-col justify-center'
                  }>
                  <li className={'flex justify-center'} onClick={() => setSearchString('')}>
                    <span className="flex justify-center items-center text-light-blue hover:text-primary-blue cursor-pointer transition-colors">
                      <span title={'Zabaleno'}>
                        <ProcessStatusIcon status={'packed'} size="lg" />
                      </span>
                    </span>
                  </li>
                </NavLink>
                <NavLink
                  to={`/dashboard/dispatched${routeParams}`}
                  className={
                    (currentOrderProcessStatus || headerTitle) === 'dispatched'
                      ? 'menuItem-active h-14 flex flex-col justify-center'
                      : 'h-14 flex flex-col justify-center'
                  }>
                  <li className={'flex justify-center'} onClick={() => setSearchString('')}>
                    <span className="flex justify-center items-center text-light-blue hover:text-primary-blue cursor-pointer transition-colors">
                      <span title={'Předáno dopravci'}>
                        <ProcessStatusIcon status={'dispatched'} size="lg" />
                      </span>
                    </span>
                  </li>
                </NavLink>
                <NavLink
                  to={`/dashboard/picking_error${routeParams}`}
                  className={
                    (currentOrderProcessStatus || headerTitle) === 'picking_error'
                      ? 'menuItem-active h-14 flex flex-col justify-center'
                      : 'h-14 flex flex-col justify-center'
                  }>
                  <li className={'flex justify-center'} onClick={() => setSearchString('')}>
                    <span className="flex justify-center items-center text-light-blue hover:text-primary-blue cursor-pointer transition-colors">
                      <span title={'Chyba vykladnění'}>
                        <ProcessStatusIcon status={'picking_error'} size="lg" />
                      </span>
                    </span>
                  </li>
                </NavLink>
                <NavLink
                  to={`/dashboard/history`}
                  className={
                    currentOrderProcessStatus === 'history'
                      ? 'menuItem-active h-14 flex flex-col justify-center mt-5'
                      : 'h-14 flex flex-col justify-center mt-5'
                  }>
                  <li className={'flex justify-center'} onClick={() => setSearchString('')}>
                    <span className="flex justify-center items-center text-light-blue hover:text-primary-blue cursor-pointer transition-colors">
                      <span title={'Historie'}>
                        <FontAwesomeIcon icon={['far', 'clock-rotate-left']} size={'lg'} />
                      </span>
                    </span>
                  </li>
                </NavLink>
                <NavLink
                  to={`/groups/${routeParams}`}
                  className={
                    headerTitle === 'groups'
                      ? 'menuItem-active h-14 flex flex-col justify-center mt-5'
                      : 'h-14 flex flex-col justify-center mt-5'
                  }>
                  <li className={'flex justify-center'} onClick={() => setSearchString('')}>
                    <span className="flex justify-center items-center text-light-blue hover:text-primary-blue cursor-pointer transition-colors">
                      <span title={'Skupiny'}>
                        <FontAwesomeIcon icon={['far', 'boxes']} size={'lg'} />
                      </span>
                    </span>
                  </li>
                </NavLink>
                <NavLink
                  to={`/dispatch/${routeParams}`}
                  className={
                    currentOrderProcessStatus === 'dispatch'
                      ? 'menuItem-active h-14 flex flex-col justify-center mt-5'
                      : 'h-14 flex flex-col justify-center mt-5'
                  }>
                  <li className={'flex justify-center'} onClick={() => setSearchString('')}>
                    <span className="flex justify-center items-center text-light-blue hover:text-primary-blue cursor-pointer transition-colors">
                      <span title={'Svoz'}>
                        <FontAwesomeIcon icon={['far', 'truck-loading']} size={'lg'} />
                      </span>
                    </span>
                  </li>
                </NavLink>
              </ul>
            </nav>
            {isLoggedIn ? (
              <nav className="mt-auto flex flex-col gap-10 items-center menuItems">
                <ul className={'w-full flex flex-col gap-3'}>
                  <NavLink
                    to={'/settings'}
                    className={
                      splitLocation[1] === 'settings'
                        ? 'menuItem-active h-14 flex flex-col justify-center'
                        : 'h-14 flex flex-col justify-center'
                    }>
                    <li className={'flex justify-center'}>
                      <span title={'Nastavení'}>
                        <FontAwesomeIcon
                          icon={['far', 'cog']}
                          size="lg"
                          className={
                            splitLocation[1] === 'settings'
                              ? 'cursor-pointer text-primary-blue'
                              : 'cursor-pointer text-light-blue hover:text-primary-blue transition-colors'
                          }
                        />
                      </span>
                    </li>
                  </NavLink>
                  <span title={'Odhlásit'} className={'self-center'}>
                    <FontAwesomeIcon
                      icon={['far', 'sign-out-alt']}
                      size="lg"
                      className="cursor-pointer text-light-blue hover:text-danger transition-colors"
                      onClick={() => {
                        handleLogout(() => history.push('/login'))
                      }}
                    />
                  </span>
                </ul>
              </nav>
            ) : null}
          </nav>
        </aside>
      </div>
      <div className={'flex-1 flex flex-col'}>
        {isSettingsView === true ? (
          <div className={'flex flex-col h-full justify-between'}>{children}</div>
        ) : (
          <>
            {hasMainHeader && <Header isMainHeader={hasMainHeader} activeProcessStatus={headerTitle} />}
            <div className={'h-full'}>{children}</div>
          </>
        )}
      </div>
    </div>
  )
}
