import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Schema } from '../lib'
import { OrderGroup } from 'depoto-core'
import { Btn, Loader, Group as GroupComponent, DateFormatted } from '../components'
import { useCore } from '../hooks'

export const GroupClearanceItems = () => {
  const [group, setGroup] = useState<OrderGroup>()
  const { core, isFetching, setIsFetching } = useCore()
  const history = useHistory()
  const { groupId }: any = useParams()

  useEffect(() => {
    getGroup()
  }, [])

  const getGroup = async () => {
    setIsFetching(true)
    const res = await core?.services.order.getOrderGroupById(groupId, Schema.orderGroup.clearanceItems)
    if (res && res.id > 0) {
      setGroup(new OrderGroup(res))
    }
    setIsFetching(false)
  }

  return isFetching ? (
    <Loader size="fill-container" />
  ) : (
    <div className="flex flex-col p-3">
      {/*<pre>{JSON.stringify(group?.clearanceItems, null, 2)}</pre>*/}
      <p className="font-bold text-xl">
        Skupina {group?.name} [{group?.id}]
      </p>
      <div className="grid grid-cols-8 gap-1 pt-3 pb-3 p-1">
        <div className="font-bold" style={{ gridColumn: '1 / span 2' }}>
          Název
        </div>
        <div className="font-bold">Ean</div>
        <div className="font-bold">Kód</div>
        <div className="font-bold">Šarže</div>
        <div className="font-bold">Expirace</div>
        <div className="font-bold">Pozice</div>
        <div className="font-bold">Počet kusů</div>
      </div>
      {group &&
        group?.clearanceItems?.length > 0 &&
        group?.clearanceItems.map(cli => (
          <div
            className="grid grid-cols-8 gap-1 p-1"
            style={{ borderBottom: '1px solid #e5e5e5' }}
            key={`${group.id}_${cli.id}`}>
            <div style={{ gridColumn: '1 / span 2' }}>{cli.product?.fullName}</div>
            <div>{cli.product?.ean}</div>
            <div>{cli.product?.code}</div>
            <div>{cli.batch}</div>
            <div>
              <DateFormatted date={cli.expirationDate} />
            </div>
            <div>{cli.position}</div>
            <div>{cli.amount}ks</div>
          </div>
        ))}
      <button onClick={() => history.goBack()} className="btn btn-primary fixed top-25 right-3 w-48">
        Zpět na objednávky
      </button>
    </div>
  )
}
