import { createContext, useContext } from 'react'
import { Order } from 'depoto-core'

// Context to provide "server-state" of order

export interface OrderDetailContextType {
  order: Order | null
}

const defaultOrderDetail: OrderDetailContextType = {
  order: null,
}

export const useOrderDetailContext = () => {
  return useContext(OrderDetailContext)
}

export const OrderDetailContext = createContext<OrderDetailContextType>(defaultOrderDetail)
