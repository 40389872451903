import { Description, Dialog, DialogBackdrop, DialogDescription, DialogPanel, DialogTitle } from '@headlessui/react'
import { Fragment } from 'react'

interface ModalProps {
  isOpen: boolean
  closable?: boolean
  onClose: () => void
  children: React.ReactNode
  className?: string
  title?: string
  description?: string
  titleClassName?: string
}
export default function Modal({
  isOpen,
  closable = true,
  onClose,
  children,
  className,
  title,
  description,
  titleClassName,
}: ModalProps) {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      transition
      className="fixed inset-0 flex w-screen items-center justify-center bg-black/30 p-4 transition duration-150 ease-out data-[closed]:opacity-0">
      {/* The backdrop, rendered as a fixed sibling to the panel container */}

      <DialogBackdrop transition className="fixed inset-0 bg-black/30 duration-150 ease-out data-[closed]:opacity-0" />

      {/* Full-screen container to center the panel */}
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <DialogPanel
          transition
          className={`inline-block w-full max-w-3xl p-6 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl bg-base-blue duration-150 ease-out data-[closed]:scale-95 data-[closed]:opacity-0 ${className}`}>
          {title && (
            <DialogTitle as="h3" className={`text-xl font-medium leading-6 text-black mb-2 ${titleClassName}`}>
              {title}
            </DialogTitle>
          )}
          {description && <Description className="text-sm text-slate-600 mb-4">{description}</Description>}
          {children}
        </DialogPanel>
      </div>
    </Dialog>
  )
}
