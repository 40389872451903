import React, { useState, useEffect } from 'react'
import { Order as OrderComponent } from './Order'
import { useCore } from '../hooks'
import { Order } from 'depoto-core'

export const OrdersHistory = () => {
  const [orders, setOrders] = useState<Order[]>([])
  const { ordersHistory } = useCore()

  useEffect(() => {
    setOrders(ordersHistory.map(o => new Order(o)))
  }, [ordersHistory])

  return (
    <div className="grid xs:grid-cols-3 sm:grid-cols-4 xl:grid-cols-6 gap-1 p-3">
      {orders.length > 0 && orders.map(o => <OrderComponent key={o.id} order={o} />)}
    </div>
  )
}
