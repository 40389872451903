import { Product } from 'depoto-core'
import Modal from './Modal'

import { useRef, useState } from 'react'

export interface SerialNumbersModalProps {
  isOpen: boolean
  onClose: () => void
  serialNumberPicking?: SerialNumbersPickingParams
  onSerialNumbersPicked: (serialNumbers: string[]) => void
}

export interface SerialNumbersHookProps {
  onSerialNumbersPicked?: (serialNumbers: string[]) => void
}

export interface SerialNumbersPickingParams {
  existingSerialNumbers?: string[]
  totalAmount?: number
  product?: Product
}

export function useSerialNumbersModal({ onSerialNumbersPicked }: SerialNumbersHookProps = {}): {
  serialNumbersModalProps: SerialNumbersModalProps
  open: () => void
  close: () => void
} {
  const [isOpen, setIsOpen] = useState(false)
  const [serialNumberPicking, setSerialNumberPicking] = useState<SerialNumbersPickingParams | undefined>(undefined)

  return {
    serialNumbersModalProps: {
      isOpen,
      onClose: () => setIsOpen(false),
      serialNumberPicking,
      onSerialNumbersPicked,
    },
    open: () => setIsOpen(true),
    close: () => setIsOpen(false),
  }
}

export default function SerialNumbersModal({
  isOpen,
  onClose,
  serialNumberPicking,
  onSerialNumbersPicked,
}: SerialNumbersModalProps) {
  const [items, setItems] = useState<string[]>([])
  const [inputValue, setInputValue] = useState('')
  const inputRef = useRef<HTMLDivElement>(null)

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const inputValue = (e.target as HTMLDivElement).innerText
    if (e.key === 'Enter') {
      e.preventDefault()
      if (inputValue.trim()) {
        setItems([...items, inputValue.trim()])
        setInputValue('')
        if (inputRef.current) {
          inputRef.current.innerHTML = ''
        }
      }
    } else if (e.key === 'Backspace' && (inputValue === '' || inputValue == '\n') && items.length > 0) {
      e.preventDefault()
      setItems(items.slice(0, -1))
    }
  }

  const removeItem = (index: number) => {
    setItems(items.filter((_, i) => i !== index))
  }

  const handleInput = (e: React.FormEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement
    setInputValue(target.innerText)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Seriová čísla" description="Zadejte seriová čísla">
      <div
        className="border rounded-xl p-2 gap-2 cursor-text flex flex-wrap items-start focus-within:outline focus-within:outline-1 focus-within:outline-gray-600"
        onClick={() => inputRef.current?.focus()}>
        {items.map((item, index) => (
          <span key={index} className="bg-gray-200 rounded-lg px-2 py-1">
            {item}
            <button
              onClick={e => {
                e.stopPropagation()
                removeItem(index)
              }}
              className="ml-2 text-red-500 font-bold">
              ×
            </button>
          </span>
        ))}
        <div
          ref={inputRef}
          className="inline-block vertical-align-middle outline-none px-2 py-1"
          contentEditable
          onKeyDown={handleKeyDown}
          onInput={handleInput}
          suppressContentEditableWarning={true}
        />
      </div>
    </Modal>
  )
}
