import React, { useEffect, useMemo, useState } from 'react'
import { NavLink, useHistory, useParams } from 'react-router-dom'
import { Order as OrderComponent } from './Order'
import { Loader } from './Loader'
import { useCore } from '../hooks'
import {
  chooseEnabledFilter,
  isNonEmptyValue,
  KeyEventType,
  printTicket,
  removeEmptyProperties,
  Schema,
  useSearchParams,
} from '../lib'
import { Order, Package } from 'depoto-core'
import { Btn } from './Btn'
import { PackageBarcodeUtil } from '../lib/PackageBarcode'
import { GroupRecord } from '../store/core/reducer'
import { usePaginatedOrders } from '../lib/hooks/usePaginatedOrders'
import { useIntersect } from '../lib/hooks/useIntersect'
import { faArrowDown } from '@fortawesome/pro-light-svg-icons'

export const Orders = () => {
  const [isFetchingNextPage, setIsFetchingNextPage] = useState<boolean>(false)
  const [paginator, setPaginator] = useState({ current: 0, endPage: 0, totalCount: 0 })
  const [batchGroupStatus, setBatchGroupStatus] = useState({
    isVisible: false,
    progress: 0,
    all: 0,
    currentOperation: '',
  })
  const [batchGroupErrors, setBatchGroupErrors] = useState<{ orderId: number; error: string }[]>([])
  // todo do core reduceru history orderGroups, klic = id groupy. historii vypsat na grupe, pokud existuje, s proklikem na jednotlivy objednavky
  const {
    core,
    isFetching,
    setIsFetching,
    isStrictDepotFilter,
    searchType,
    setSearchType,
    itemCount,
    setItemCount,
    ordersHistory,
    childCompanyId,
    selectedCarrierId,
    selectedCheckoutId,
    selectedCountry,
    selectedDepotId,
    selectedTagId,
    groupHistory,
    selectedDate,
    setGroupHistory,
    setSearchString,
    setSelectedTagId,
    tags,
  } = useCore()

  const history = useHistory()
  const { processStatus, searchString }: any = useParams()

  useEffect(() => {
    if (searchString) {
      setSearchString(searchString)
    }
  }, [searchString])

  const { carrierId, checkoutId, countryId, depotId, groupId }: any = useSearchParams()
  const groupRecord: GroupRecord = groupHistory?.[groupId] || {
    log: [],
    errors: [],
    date: +new Date(),
    isVisible: true,
  }

  // Filters which are using searchString for adding custom filter params to request
  const plaintextFilterParams: any = useMemo(() => {
    const PTFEnabled = (type: string) => searchString && searchString?.length >= 0 && searchType === type
    const PTFNumEnabled = (type: string) => searchString && !isNaN(Number(searchString)) && searchType === type
    const RFilters = removeEmptyProperties(
      {
        box: (PTFEnabled('box') || /^(box|pr)-.+/i.test(searchString || '')) && searchString,
        reservationNumber: PTFNumEnabled('reservationNumber') && Number(searchString),
        billNumber: PTFNumEnabled('billNumber') && Number(searchString),
        package: PTFEnabled('package') && searchString,
        customerFulltext: PTFEnabled('customer') && searchString,
      },
      el => el !== false,
    )
    return Object.keys(RFilters).length > 1 ? { fulltext: searchString } : RFilters
  }, [searchString, searchType])

  useEffect(() => {
    const currentTag = tags.find(t => t.id === selectedTagId)
    if (currentTag?.type !== 'order') {
      setSelectedTagId(undefined)
    }
  }, [selectedTagId])

  const filters = useMemo(() => {
    return removeEmptyProperties(
      {
        processStatus: !searchString ? (processStatus === 'all' ? undefined : processStatus) : undefined,
        status: ['recieved', 'received', 'picking', 'packing'].includes(processStatus) ? 'reservation' : undefined,
        carriers: [chooseEnabledFilter([carrierId, selectedCarrierId])],
        checkouts: [chooseEnabledFilter([Number(checkoutId), Number(selectedCheckoutId)])],
        shippingCountries: [chooseEnabledFilter([countryId, selectedCountry])],
        companies: [childCompanyId],
        group: Number(groupId),
        tags: [selectedTagId],
        processStatusChange: selectedDate && new Date(selectedDate).toISOString(),
        [isStrictDepotFilter ? 'onlyDepots' : 'depots']: [chooseEnabledFilter([depotId, selectedDepotId])],

        ...plaintextFilterParams,
      },
      val => (Array.isArray(val) ? val.every(el => el !== 'all') : val !== 'all'),
    )
  }, [
    carrierId,
    checkoutId,
    countryId,
    childCompanyId,
    selectedCarrierId,
    selectedCheckoutId,
    selectedCountry,
    selectedDepotId,
    selectedTagId,
    groupId,
    depotId,
    isStrictDepotFilter,
    processStatus,
    plaintextFilterParams,
    selectedDate,
  ])

  const { refresh, orders, loading, loadMore, isLastPage, totalCount } = usePaginatedOrders({
    filters,
    sort: ['recieved', 'picking', 'packing'].includes(processStatus)
      ? 'priority'
      : ['dispatched', 'delivered', 'returned', 'packed'].includes(processStatus)
      ? 'dateExpedition'
      : 'dateCreated',
    direction: 'DESC',
    schema: Schema.order.list,
  })

  // Open order, when after filtering only one order is left
  useEffect(() => {
    if (!loading && orders.length === 1) {
      history.push(`/order/${orders[0].id}`)
    }
  }, [loading, orders])

  useEffect(() => {
    setItemCount(totalCount)
  }, [totalCount])

  const { measureRef, isIntersecting } = useIntersect()

  useEffect(() => {
    if (isIntersecting) {
      loadMore()
    }
  }, [isIntersecting])

  const resolveGroup = async () => {
    setIsFetching(true)
    updateBatchGroupStatus({ isVisible: true, progress: 0, all: 0, currentOperation: 'init, prepare orders' }, [])
    const groupRecord: GroupRecord = groupHistory?.[groupId] || {
      log: [],
      errors: [],
      date: +new Date(),
      isVisible: true,
    }
    console.log({ groupRecord })
    const errors = []
    // console.warn(orders, groupId, paginator)
    let allOrdersInGroup = [...orders]
    // if (paginator.endPage > paginator.current) {
    const group = await core?.services.order.getOrderGroupById(groupId, Schema.orderGroup.forBatchUpdate)
    allOrdersInGroup = group?.orders || []
    // }
    updateBatchGroupStatus({
      isVisible: true,
      progress: 0,
      all: allOrdersInGroup.length,
      currentOperation: `orders prepared ${allOrdersInGroup.length}`,
    })
    let x = 0
    for (let o of allOrdersInGroup) {
      let hasError = false
      try {
        o.items = o.items.map(oit => ({ ...oit, ...{ picked: true, packed: true } }))
        updateBatchGroupStatus({
          isVisible: true,
          progress: x,
          all: allOrdersInGroup.length,
          currentOperation: `order ${o.id}: setPicked`,
        })
        groupRecord.log.push({
          orderId: o.id,
          reservationNumber: o.reservationNumber,
          record: `order ${o.reservationNumber}: setPicked`,
        })
        const resPick = await core?.services.order.updateOrderPickedPacked(o)
        console.warn({ resPick })
        updateBatchGroupStatus({
          isVisible: true,
          progress: x,
          all: allOrdersInGroup.length,
          currentOperation: `order ${o.id}: setPicked OK`,
        })
        groupRecord.log.push({
          orderId: o.id,
          reservationNumber: o.reservationNumber,
          record: `order ${o.reservationNumber}: setPicked OK`,
        })
        let resCreatePack = null
        if (o.packages.length > 0 && o.packages[0].id > 0) {
          resCreatePack = o.packages[0]
          updateBatchGroupStatus({
            isVisible: true,
            progress: x,
            all: allOrdersInGroup.length,
            currentOperation: `order ${o.id}: use Package ${resCreatePack?.id}`,
          })
          groupRecord.log.push({
            orderId: o.id,
            reservationNumber: o.reservationNumber,
            record: `order ${o.reservationNumber}: use Package ${resCreatePack?.id}`,
          })
        } else {
          resCreatePack = await core?.services.pack.create(
            new Package({
              order: o,
              carrier: o.carrier,
              items: o.items,
            }),
          )
          updateBatchGroupStatus({
            isVisible: true,
            progress: x,
            all: allOrdersInGroup.length,
            currentOperation: `order ${o.id}: createPackage ${resCreatePack?.id}`,
          })
          groupRecord.log.push({
            orderId: o.id,
            reservationNumber: o.reservationNumber,
            record: `order ${o.reservationNumber}: createPackage ${resCreatePack?.id}`,
          })
        }
        let resSendPackage = null
        console.log({ resCreatePack })
        if (!!resCreatePack?.sent) {
          resSendPackage = resCreatePack
          updateBatchGroupStatus({
            isVisible: true,
            progress: x,
            all: allOrdersInGroup.length,
            currentOperation: `order ${o.id}: use sent Package ${resSendPackage?.id}`,
          })
          groupRecord.log.push({
            orderId: o.id,
            reservationNumber: o.reservationNumber,
            record: `order ${o.reservationNumber}: use sent Package ${resSendPackage?.id}`,
          })
        } else {
          resSendPackage = await core?.services.pack.send(resCreatePack!)
          hasError = !resSendPackage
          console.warn({ resSendPackage, hasError })
          updateBatchGroupStatus({
            isVisible: true,
            progress: x,
            all: allOrdersInGroup.length,
            currentOperation: `order ${o.id} (reservationNumber: ${o.reservationNumber}): sendPackage ${resSendPackage?.id}`,
          })
          groupRecord.log.push({
            orderId: o.id,
            reservationNumber: o.reservationNumber,
            record: `order ${o.reservationNumber}: sendPackage ${resSendPackage?.id}`,
          })
        }
        if (hasError) {
          // todo: skupiny odeslani neposilat order do stavu packed po chybe!!!
          console.log('hasError, end here')
          setIsFetching(false)
          groupRecord.errors.push({
            orderId: o.id,
            reservationNumber: o.reservationNumber,
            record: `order ${o.reservationNumber}: error sending Package ${resCreatePack?.id}`,
          })
          const nextGroupHistory = { ...groupHistory }
          nextGroupHistory[groupId] = groupRecord
          setGroupHistory(nextGroupHistory)
          return // todo neprerusovat, jit na dalsi order..
        }
        const pack = await core?.services.pack.getById(resSendPackage?.id, {
          id: null,
          ticketUrl: null,
          carrier: { id: null },
        })
        console.warn({ pack })
        updateBatchGroupStatus({
          isVisible: true,
          progress: x,
          all: allOrdersInGroup.length,
          currentOperation: `order ${o.id}: getPackDetails ${pack?.id}`,
        })
        if (window._depoto) {
          const resPrintTicket = await printTicket(pack!) // todo carrier to schema [x] // neposilat veci znova// nechat viset stav resolvu.
          // console.warn(resPrintTicket)
          updateBatchGroupStatus({
            isVisible: true,
            progress: x,
            all: allOrdersInGroup.length,
            currentOperation: `order ${o.id}: printPack ${pack?.id}`,
          })
          groupRecord.log.push({
            orderId: o.id,
            reservationNumber: o.reservationNumber,
            record: `order ${o.reservationNumber}: printPackage ${pack?.id}`,
          })
        }
        if (o.processStatus?.id === 'packed') {
          updateBatchGroupStatus({
            isVisible: true,
            progress: x,
            all: allOrdersInGroup.length,
            currentOperation: `order ${o.id}: is packed. ORDER DONE`,
          })
          groupRecord.log.push({
            orderId: o.id,
            reservationNumber: o.reservationNumber,
            record: `order ${o.reservationNumber}: is packed already. ORDER DONE!`,
          })
        } else {
          const resStatusUpdate = await core?.services.order.updateProcessStatus(o, 'packed', '')
          console.warn({ resStatusUpdate })
          updateBatchGroupStatus({
            isVisible: true,
            progress: x,
            all: allOrdersInGroup.length,
            currentOperation: `order ${o.id}: updateStatus to packed. ORDER DONE`,
          })
          groupRecord.log.push({
            orderId: o.id,
            reservationNumber: o.reservationNumber,
            record: `order ${o.reservationNumber}: updateStatus to packed. ORDER DONE!`,
          })
        }
      } catch (e: unknown) {
        // First, ensure e is not null/undefined
        if (!e) return

        // Create a type guard for error-like objects
        const isErrorLike = (err: unknown): err is { message?: string } => {
          return typeof err === 'object' && err !== null
        }

        // Type guard check
        if (!isErrorLike(e)) return

        console.warn(e)

        updateBatchGroupStatus({
          isVisible: true,
          progress: x,
          all: allOrdersInGroup.length,
          currentOperation: e.message ? `order ${o.id}: ERROR: ${e.message ?? JSON.stringify(e)}` : '',
        })
        errors.push({
          orderId: o.id,
          error: `[reservationNumber: ${o.reservationNumber}]: ${e.message ?? JSON.stringify(e)}`,
        })
        groupRecord.errors.push({
          orderId: o.id,
          reservationNumber: o.reservationNumber,
          record: `order ${o.reservationNumber}: ERROR: ${e.message ?? JSON.stringify(e)}`,
        })
      }
      x++
    }
    updateBatchGroupStatus(
      {
        isVisible: true,
        progress: x,
        all: allOrdersInGroup.length,
        currentOperation: `Všechny objednávky ve skupině byly zpracovány.`,
      },
      errors,
    )
    groupRecord.log.push({
      orderId: 0,
      reservationNumber: 0,
      record: `GROUP DONE!`,
    })
    const nextGroupHistory = { ...groupHistory }
    nextGroupHistory[groupId] = groupRecord
    setGroupHistory(nextGroupHistory)
    setIsFetching(false)
  }

  const toggleGroupLogVisibility = () => {
    const currentGroupRecord: GroupRecord = groupHistory?.[groupId] || {
      log: [],
      errors: [],
      date: +new Date(),
      isVisible: false,
    }
    const nextGroupHistory = { ...groupHistory }
    currentGroupRecord.isVisible = !currentGroupRecord.isVisible
    nextGroupHistory[groupId] = currentGroupRecord
    setGroupHistory(nextGroupHistory)
  }

  const updateBatchGroupStatus = (status?: any, errors?: any[]) => {
    if (!window.batchGroupStatus) {
      window.batchGroupStatus = {}
    }
    window.batchGroupStatus[groupId] = {
      // keep things till reload
      status: status ?? batchGroupStatus,
      errors: errors ?? batchGroupErrors,
    }
    setBatchGroupStatus(status ?? batchGroupStatus)
    setBatchGroupErrors(errors ?? batchGroupErrors)
  }

  useEffect(() => {
    if ((!processStatus || processStatus == 'all') && !groupId && !searchString) {
      setTimeout(() => history.push('/dashboard/packing'), 10)
    } else {
      if (window.batchGroupStatus && !!window.batchGroupStatus[groupId]?.status) {
        setBatchGroupStatus(window.batchGroupStatus[groupId].status)
        setBatchGroupErrors(window.batchGroupStatus[groupId].errors)
      }
      refresh()
      const processCode = (c: CustomEvent | Event | any) => {
        if (!location.href?.includes('/order/')) {
          history.push(`/search/${PackageBarcodeUtil.getPackageCodeFromBarcode(c.detail)}`)
        }
      }
      document.addEventListener(KeyEventType.BARCODE, processCode)
      return () => {
        document.removeEventListener(KeyEventType.BARCODE, processCode)
      }
    }
  }, [carrierId, checkoutId, countryId, depotId, core, processStatus, searchString])

  if (searchString?.length && orders?.length === 1) {
    history.push(`/order/${orders[0].id}`)
    return null
  }

  return isFetching || (loading && orders.length <= 0) ? (
    <Loader size="fill-container" />
  ) : (
    <div className={'p-4 grid gap-4 orderColumns'}>
      {orders.length > 0 && orders.map(o => <OrderComponent order={o} key={o.id} />)}
      {!isLastPage && (
        <Btn
          ref={measureRef}
          id={'next-orders'}
          children={'Načíst další'}
          className={'btn-primary'}
          icon={faArrowDown}
          title={'Načíst další'}
          isLoading={loading}
          isDisabled={loading}
          onClick={loadMore}
        />
      )}
      {batchGroupStatus.isVisible && (
        <div className="fixed top-25" style={{ width: 'calc(100% - 90px)' }}>
          <div className="card bg-white">
            <div className="flex flex-row w-full">
              <p className="text-black">
                <strong>Progres:</strong> {(batchGroupStatus.progress / (batchGroupStatus.all / 100) || 0).toFixed(2)}%
                <br />
                <strong>Celkem:</strong> {batchGroupStatus.progress} / {batchGroupStatus.all}
                <br />
                <strong>Operace:</strong> {batchGroupStatus.currentOperation}
              </p>
              {batchGroupStatus.progress === batchGroupStatus.all && batchGroupStatus.all > 0 && (
                <div className="fixed top-16 mt-3 right-48">
                  {/*<div className="ml-auto mt-8">*/}
                  <button
                    className="btn text-black bg-white"
                    onClick={() => {
                      setBatchGroupStatus({ ...batchGroupStatus, isVisible: false })
                    }}>
                    Zavřít přehled
                  </button>
                </div>
              )}
            </div>
            {batchGroupErrors.length > 0 && (
              <div>
                <p className="font-bold">Některé skočily chybou:</p>
                <ul>
                  {batchGroupErrors.map((e, i) => (
                    <li key={`error_${i}`} className="cursor-pointer">
                      <NavLink to={`/order/${e.orderId}`}>{e.error}</NavLink>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="w-full relative bottom-0">
            <div
              className="bg-primary h-1"
              style={{ width: `${batchGroupStatus.progress / (batchGroupStatus.all / 100)}%` }}
            />
          </div>
        </div>
      )}
      {groupId !== undefined && groupHistory?.[groupId]?.log?.length > 0 && groupHistory?.[groupId]?.isVisible && (
        <div className="fixed top-25" style={{ width: 'calc(100% - 90px)' }}>
          <div className="card bg-white h-auto max-h-72 scrollable-content">
            <div className="flex flex-row w-full">
              <p className="text-black">
                <strong>OrderGroup ID:</strong> {groupId}
              </p>
            </div>
            {groupHistory[groupId]?.log?.length > 0 && (
              <div>
                <p className="font-bold">Log:</p>
                <ul>
                  {groupHistory[groupId]?.log.map((l, i) => (
                    <li key={`glog_${i}`} className="cursor-pointer">
                      <NavLink to={`/order/${l.orderId}`}>{l.record}</NavLink>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {groupHistory[groupId]?.log?.length > 0 && (
              <div>
                <p className="font-bold text-warning">Některé akce skočily chybou:</p>
                <ul>
                  {groupHistory[groupId]?.errors.map((l, i) => (
                    <li key={`gerror_${i}`} className="cursor-pointer">
                      <NavLink to={`/order/${l.orderId}`}>{l.record}</NavLink>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      )}
      {groupId !== undefined && (
        <button
          onClick={() => (isFetching ? null : resolveGroup())}
          disabled={isFetching}
          className="btn btn-primary fixed top-25 right-3 w-44">
          Odeslat skupinu
        </button>
      )}
      {groupId !== undefined && (
        <button
          onClick={() => (isFetching ? null : history.push(`/groups/${groupId}`))}
          disabled={isFetching}
          className="btn btn-primary fixed top-32 right-3 w-44">
          Zobrazit položky
        </button>
      )}
      {groupId !== undefined && batchGroupStatus.all > 0 && !batchGroupStatus.isVisible && (
        <button
          onClick={() => setBatchGroupStatus({ ...batchGroupStatus, isVisible: true })}
          className="btn btn-primary fixed top-44 mt-1 right-3 w-44">
          Zobrazit přehled
        </button>
      )}
      {groupId !== undefined && groupRecord && groupHistory?.[groupId]?.log?.length > 0 && (
        <button onClick={() => toggleGroupLogVisibility()} className="btn btn-primary fixed top-25 right-48 mr-1 w-44">
          {groupRecord.isVisible ? 'Skrýt log' : 'Zobrazit log'}
        </button>
      )}
    </div>
  )
}
