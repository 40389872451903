import { Checkbox } from '../../../components/Checkbox'
import { ClearanceItem, Order } from 'depoto-core'
import React, { Fragment, ReactNode, useMemo } from 'react'
import { useCore } from '../../../hooks'
import { useGQL } from '../../../lib/GQLContext'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import clsx from 'clsx'
import { Loader } from '../../../components'

export function PickingCheckboxCell({ orderId, clearanceItemId }: { orderId: number; clearanceItemId: string }) {
  const gql = useGQL()
  const queryClient = useQueryClient()

  const order = queryClient.getQueryData<Order>(['order', orderId])

  const clearanceItem = useMemo(
    () => order?.clearanceItems?.find(el => el.id === clearanceItemId),
    [order, clearanceItemId],
  )

  const mutatingField: 'picked' | 'packed' =
    order.processStatus.id === 'picking' ? 'picked' : order.processStatus.id === 'packing' ? 'packed' : null

  const { mutate, isLoading } = useMutation({
    mutationFn: () => {
      const ci = order.clearanceItems.find(el => el.id === clearanceItemId)

      return gql('mutation')({
        updateClearanceItem: [
          {
            id: clearanceItemId,
            order: orderId,
            [mutatingField]: ci[mutatingField] === ci.amount ? 0 : ci.amount,
          },
          {
            data: {
              picked: true,
              packed: true,
              amount: true,
            },
            errors: true,
          },
        ],
      })
    },
    onSuccess: data =>
      queryClient.setQueryData(['order', orderId], (old: Order) => ({
        ...old,
        clearanceItems: old.clearanceItems.map(oldCi =>
          oldCi.id === clearanceItemId ? { ...oldCi, ...(data?.updateClearanceItem?.data ?? {}) } : oldCi,
        ),
      })),
  })

  if (!(order.processStatus.id === 'picking' || order.processStatus.id === 'packing')) return null

  if (isLoading)
    return (
      <div className={'flex items-center justify-center flex-1 h-8'}>
        <div className="loader-small ease-linear" />
      </div>
    )

  return (
    <div className={'flex items-center justify-center flex-1 h-8'}>
      <Checkbox checked={clearanceItem[mutatingField] === clearanceItem.amount} onChange={() => mutate()} />
    </div>
  )
}
