import React from 'react'
import { Order } from 'depoto-core'
import { DateFormatted } from './DateFormatted'
import { LinkExternal } from './LinkExternal'

type Props = {
  order: Order
}
export const OrderInfo: React.FC<Props> = ({ order }) => {
  const clientUrl = 'https://app.depoto.cz'
  return (
    <div className={'flex flex-col gap-4'}>
      <h1 className={'text-black text-xl font-bold'}>Informace</h1>
      <div className={'box'}>
        <div className={'p-4 flex justify-between items-center border-b border-cool-gray'}>
          <div className={'text-base text-black font-semibold'}>Pokladna</div>
          <div className={'text-base text-primary-blue font-semibold'}>{order.checkout?.name || ''}</div>
        </div>
        <div className={'p-4 flex justify-between items-center border-b border-cool-gray'}>
          <div className={'text-base text-black font-semibold'}>Objednávka</div>
          <div className={'text-base text-primary-blue font-semibold'}>
            {!!order.billNumber ? (
              <LinkExternal url={`${clientUrl}/order/${order.id}`} label={`${order.billNumber}`} />
            ) : null}
          </div>
        </div>
        <div className={'p-4 flex justify-between items-center border-b border-cool-gray'}>
          <div className={'text-base text-black font-semibold'}>Číslo rezervace</div>
          <div className={'text-base text-black font-regular'}>
            {!!order.reservationNumber ? (
              <LinkExternal url={`${clientUrl}/order/${order.id}`} label={`${order.reservationNumber}`} />
            ) : (
              order.reservationNumber
            )}
          </div>
        </div>
        <div className={'p-4 flex justify-between items-center border-b border-cool-gray'}>
          <div className={'text-base text-black font-semibold'}>Datum vytvoření</div>
          <div className={'text-base text-black font-regular'}>
            <DateFormatted date={order.dateCreated} hoursAndMins={true} />
          </div>
        </div>
        <div className={'p-4 flex justify-between items-center border-b border-cool-gray'}>
          <div className={'text-base text-black font-semibold'}>Vytvořeno uživatelem</div>
          <div className={'text-base text-primary-blue font-semibold'}>{order.createdBy?.name}</div>
        </div>
        <div className={'p-4 flex justify-between items-center border-b border-cool-gray'}>
          <div className={'text-base text-black font-semibold'}>Daňový doklad</div>
          <div className={'text-base text-black font-regular'}>
            {!!order.billNumber ? (
              <LinkExternal url={`${clientUrl}/order/${order.id}`} label={`${order.billNumber}`} />
            ) : (
              order.billNumber
            )}
          </div>
        </div>
        <div className={'p-4 flex justify-between items-center border-b border-cool-gray'}>
          <div className={'text-base text-black font-semibold'}>DUZP</div>
          <div className={'text-base text-black font-regular'}>
            <DateFormatted date={order.dateCreated} hoursAndMins={true} />
          </div>
        </div>
      </div>
    </div>
  )
}
