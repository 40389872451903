import { FieldValues, useController, UseControllerProps } from 'react-hook-form'
import { FormTextarea, FormTextareaProps } from '../FormTextarea'

export type ControlledTextareaProps<T extends FieldValues> = UseControllerProps<T> & Partial<FormTextareaProps>

export function ControlledTextarea<T extends FieldValues>({
  control,
  name,
  rules,
  defaultValue,
  ...rest
}: ControlledTextareaProps<T>) {
  const { field, fieldState } = useController({
    control,
    name,
    rules,
    defaultValue,
  })

  return (
    <>
      <FormTextarea {...rest} onChange={field.onChange} value={field.value} />
      {!!fieldState.error?.message && <span className={'text-red-500'}>{fieldState.error?.message}</span>}
    </>
  )
}
