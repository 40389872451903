import { Product } from 'depoto-core'
import { PhotoSwipe } from '../../../components/PhotoSwipe'
import React from 'react'

export function ProductCell({ product }: { product?: Product }) {
  const thumbUrl = product?.mainImage?.thumbnails?.length
    ? product?.mainImage.thumbnails.filter(t => t.format === 'w135')[0].url
    : ''

  const imageUrl = product?.mainImage?.thumbnails?.length
    ? product?.mainImage.thumbnails.filter(t => t.format === 'w2048')[0].url
    : ''

  return (
    <div
      className={
        product?.isFragile === false && product?.isOversize === false
          ? 'grid grid-cols-5 col-span-4 gap-2'
          : 'grid grid-cols-4 col-span-3 gap-2'
      }>
      <div className="col-end-1 self-center">
        <PhotoSwipe
          alt={''}
          caption={`
                        ${product?.fullName || product?.name ? product?.fullName || product?.name : ''} <br> 
                        ${product?.code ? 'Kód: ' + product?.code : ''} <br> 
                        ${product?.ean ? 'EAN: ' + product?.ean : ''}`}
          galleryImg={imageUrl}
          src={thumbUrl}
          thumbnails={''}
        />
      </div>
      <div
        className={
          product?.isFragile === false && product?.isOversize === false
            ? 'col-span-5 self-center font-semibold'
            : 'col-span-4 self-center font-semibold'
        }>
        {product?.fullName}
      </div>
    </div>
  )
}
