import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faMoneyBill,
  faFragile,
  faExpandAlt,
  faBoxOpen,
  faFileWord,
  faWeightScale,
  faChevronDown,
  faChevronRight,
  faHouse,
  faTruck,
  faUser,
  faHourglass,
  faCheck,
  faCheckSquare,
  faDownload,
  faArrowLeft,
  faTruckLoading,
  faPlus,
  faTrashAlt,
  faBan,
  faUndo,
  faTriangleExclamation,
  faTimes,
  faSignInAlt,
  faCheckDouble,
  faTruckRamp,
  faNote,
  faSend,
  faPrint,
  faUpload,
  faTag,
  faListSquares,
  faChevronCircleUp,
  faChevronCircleDown,
  faHouseBuilding,
  faCalendarDays,
  faWarehouseAlt,
  faCashRegister,
  faGlobe,
  faSearch,
  faShippingFast,
  faChartPieAlt,
  faFilter,
  faRecycle,
  faClockRotateLeft,
  faBoxes,
  faCog,
  faSignOutAlt,
  faMinus,
  faX,
  faListCheck,
  faTruckFast as faTruckFastRegular,
  faNotebook,
  faInfoCircle,
  faFolders,
  faChevronCircleRight,
  faHouseBuilding as faHouseBuildingRegular,
  faWarehouseAlt as faWarehouseAltRegular,
  faCashRegister as faCashRegisterRegular,
  faGlobe as faGlobeRegular,
  faSearch as faSearchRegular,
  faCartPlus,
  faConveyorBelt,
  faBoxFull,
  faPalletAlt,
  faCheckCircle,
  faExclamationTriangle,
} from '@fortawesome/pro-regular-svg-icons'
import {
  faPen,
  faXmark,
  faArrowsUpDownLeftRight,
  faRotateRight,
  faTrash,
  faTruckFast,
  faWeightHanging,
  faArrowsLeftRight,
  faArrowsUpDown,
  faArrowDownToArc,
  faArrowDown,
  faReceipt as faReceiptSolid,
  faCalendar,
  faUserVneck,
} from '@fortawesome/pro-solid-svg-icons'
import {
  faReceipt,
  faBox,
  faBoxCircleCheck,
  faWarehouseFull,
  faDolly,
} from '@fortawesome/pro-duotone-svg-icons'

// Add only the icons we use to the library
library.add(
  // Regular icons
  faMoneyBill,
  faFragile,
  faExpandAlt,
  faBoxOpen,
  faFileWord,
  faWeightScale,
  faChevronDown,
  faChevronRight,
  faHouse,
  faTruck,
  faUser,
  faHourglass,
  faCheck,
  faCheckSquare,
  faDownload,
  faArrowLeft,
  faTruckLoading,
  faPlus,
  faTrashAlt,
  faBan,
  faUndo,
  faTriangleExclamation,
  faTimes,
  faSignInAlt,
  faCheckDouble,
  faTruckRamp,
  faNote,
  faSend,
  faPrint,
  faUpload,
  faTag,
  faListSquares,
  faChevronCircleUp,
  faChevronCircleDown,
  faHouseBuilding,
  faCalendarDays,
  faWarehouseAlt,
  faCashRegister,
  faGlobe,
  faSearch,
  faShippingFast,
  faChartPieAlt,
  faFilter,
  faRecycle,
  faClockRotateLeft,
  faBoxes,
  faCog,
  faSignOutAlt,
  faMinus,
  faX,
  faListCheck,
  faTruckFastRegular,
  faNotebook,
  faInfoCircle,
  faFolders,
  faChevronCircleRight,
  faHouseBuildingRegular,
  faWarehouseAltRegular,
  faCashRegisterRegular,
  faGlobeRegular,
  faSearchRegular,
  faCartPlus,
  faConveyorBelt,
  faBoxFull,
  faPalletAlt,
  faCheckCircle,
  faExclamationTriangle,
  // Solid icons
  faPen,
  faXmark,
  faArrowsUpDownLeftRight,
  faRotateRight,
  faTrash,
  faTruckFast,
  faWeightHanging,
  faArrowsLeftRight,
  faArrowsUpDown,
  faArrowDownToArc,
  faArrowDown,
  faReceiptSolid,
  faCalendar,
  faUserVneck,
  // Duotone icons
  faReceipt,
  faBox,
  faBoxCircleCheck,
  faWarehouseFull,
  faDolly,
)

// Export icon names for type safety
export const IconNames = {
  // Regular icons
  moneyBill: ['far', 'money-bill'],
  fragile: ['far', 'fragile'],
  expandAlt: ['far', 'expand-alt'],
  boxOpen: ['far', 'box-open'],
  fileWord: ['far', 'file-word'],
  weightScale: ['far', 'weight-scale'],
  chevronDown: ['far', 'chevron-down'],
  chevronRight: ['far', 'chevron-right'],
  house: ['far', 'house'],
  truck: ['far', 'truck'],
  user: ['far', 'user'],
  hourglass: ['far', 'hourglass'],
  check: ['far', 'check'],
  checkSquare: ['far', 'check-square'],
  download: ['far', 'download'],
  arrowLeft: ['far', 'arrow-left'],
  truckLoading: ['far', 'truck-loading'],
  plus: ['far', 'plus'],
  trashAlt: ['far', 'trash-alt'],
  ban: ['far', 'ban'],
  undo: ['far', 'undo'],
  triangleExclamation: ['far', 'triangle-exclamation'],
  times: ['far', 'times'],
  signInAlt: ['far', 'sign-in-alt'],
  checkDouble: ['far', 'check-double'],
  truckRamp: ['far', 'truck-ramp'],
  note: ['far', 'note'],
  send: ['far', 'send'],
  print: ['far', 'print'],
  upload: ['far', 'upload'],
  tag: ['far', 'tag'],
  listSquares: ['far', 'list-squares'],
  chevronCircleUp: ['far', 'chevron-circle-up'],
  chevronCircleDown: ['far', 'chevron-circle-down'],
  houseBuilding: ['far', 'house-building'],
  calendarDays: ['far', 'calendar-days'],
  warehouseAlt: ['far', 'warehouse-alt'],
  cashRegister: ['far', 'cash-register'],
  globe: ['far', 'globe'],
  search: ['far', 'search'],
  shippingFast: ['far', 'shipping-fast'],
  chartPieAlt: ['far', 'chart-pie-alt'],
  filter: ['far', 'filter'],
  recycle: ['far', 'recycle'],
  clockRotateLeft: ['far', 'clock-rotate-left'],
  boxes: ['far', 'boxes'],
  cog: ['far', 'cog'],
  signOutAlt: ['far', 'sign-out-alt'],
  minus: ['far', 'minus'],
  x: ['far', 'x'],
  listCheck: ['far', 'list-check'],
  truckFastRegular: ['far', 'truck-fast'],
  notebook: ['far', 'notebook'],
  infoCircle: ['far', 'info-circle'],
  folders: ['far', 'folders'],
  chevronCircleRight: ['far', 'chevron-circle-right'],
  cartPlus: ['far', 'cart-plus'],
  conveyorBelt: ['far', 'conveyor-belt'],
  boxFull: ['far', 'box-full'],
  palletAlt: ['far', 'pallet-alt'],
  checkCircle: ['far', 'check-circle'],
  exclamationTriangle: ['far', 'exclamation-triangle'],
  // Solid icons
  pen: faPen,
  xmark: faXmark,
  arrowsUpDownLeftRight: faArrowsUpDownLeftRight,
  rotateRight: faRotateRight,
  trash: faTrash,
  truckFast: faTruckFast,
  weightHanging: faWeightHanging,
  arrowsLeftRight: faArrowsLeftRight,
  arrowsUpDown: faArrowsUpDown,
  arrowDownToArc: faArrowDownToArc,
  arrowDown: faArrowDown,
  receiptSolid: faReceiptSolid,
  calendar: faCalendar,
  userVneck: faUserVneck,
  // Duotone icons
  receipt: faReceipt,
  box: faBox,
  boxCircleCheck: faBoxCircleCheck,
  warehouseFull: faWarehouseFull,
  dolly: faDolly,
} as const 