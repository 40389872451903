import { useGlobalStore } from '../store'

import bindActions from '../store/bindActions'
import { LoginReducer, LoginSliceType } from '../store/login'
import { Fn } from 'depoto-core'

const { actions } = LoginReducer

type LoginHookReturnType = LoginSliceType & {
  handleLogin: Fn
  handleLogout: Fn
  handleAuthInit: Fn
}
export const useLogin = (): LoginHookReturnType => {
  const { state, dispatch } = useGlobalStore()
  const { login } = state
  const { handleLogin, handleLogout, handleAuthInit } = actions
  const loginActions = bindActions(
    {
      handleLogin,
      handleLogout,
      handleAuthInit,
    },
    dispatch,
  )

  return { ...login, ...loginActions }
}
