import React, { useEffect, useMemo } from 'react'
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import { Order as OrderType } from 'depoto-core'
import { formatPrice } from './OrderPrice'
import { Link } from 'react-router-dom'
import { useCore } from '../hooks'
import { faBox, faBoxCircleCheck, faDolly, faReceipt, faWarehouseFull } from '@fortawesome/pro-duotone-svg-icons'
import { faCalendar, faReceipt as faReceiptSolid, faTruckFast, faUserVneck } from '@fortawesome/pro-solid-svg-icons'
import tinycolor from 'tinycolor2'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { formatDate } from '../lib'
import 'dayjs/locale/cs'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.locale('cs')
dayjs.extend(relativeTime)
type Props = {
  order: OrderType
}

const rtf = new Intl.RelativeTimeFormat('cs', { style: 'short' })

export const Order: React.FC<Props> = ({ order }) => {
  const color = tinycolor.mix(tinycolor(order.carrier?.color || 'rgb(150, 150, 150)'), '#fff', 80).toString()

  const { company } = useCore()
  // @ts-ignore
  const hasChildren = company?.children?.length > 0

  useEffect(() => {
    tippy('[data-tippy-content]')
  }, [])

  const {
    reservationNumber,
    shippingAddress,
    boxes,
    carrier,
    note,
    privateNote,
    customer,
    isPaid,
    quantityUnavailable,
    depots,
    dateCreated,
    priority,
  } = order
  const statusIcons: {
    iconProps: FontAwesomeIconProps
    active: boolean
    tooltip: string
  }[] = useMemo(
    () => [
      {
        iconProps: {
          icon: faReceipt,
        },
        active: isPaid,
        tooltip: isPaid ? 'Zaplaceno' : 'Nezaplaceno',
      },
      {
        iconProps: {
          icon: quantityUnavailable === 0 ? faBoxCircleCheck : faBox,
        },
        active: quantityUnavailable === 0,
        tooltip: quantityUnavailable === 0 ? 'Nechybí zboží' : 'Chybí zboží',
      },
      {
        iconProps: {
          icon: depots.length === 1 ? faWarehouseFull : faDolly,
          size: 'lg',
        },
        active: true,
        tooltip: depots.length === 1 ? 'Zboží z 1 skladu' : 'Zboží z více skladů',
      },
    ],
    [],
  )

  const renderStatusIcons = () => {
    const country = order.shippingAddress?.country || 'CZ'
    const countryIcon = {
      CZ: '🇨🇿',
      SK: '🇸🇰',
    }[country]
    return (
      <div className={'flex flex-row gap-3 items-center'}>
        {statusIcons.map((statusIcon, index) => {
          const { iconProps, active, tooltip } = statusIcon
          return (
            <FontAwesomeIcon
              key={index}
              data-tippy-content={tooltip}
              size={'lg'}
              style={{
                // @ts-ignore
                '--fa-primary-color': active ? 'rgb(150,206,126)' : 'rgb(227,227,227)',
                '--fa-secondary-color': active ? 'rgb(39,178,13)' : 'rgb(152,152,152)',
                '--fa-secondary-opacity': 0.5,
                opacity: active ? 1 : 0.5,
                ...(iconProps.style || {}),
              }}
              {...iconProps}
            />
          )
        })}
        {countryIcon ? (
          <img src={`https://emojicdn.elk.sh/${countryIcon}`} alt={country} className={'h-6'} />
        ) : (
          <span>{country}</span>
        )}
      </div>
    )
  }

  const orderDetailItems: {
    label: string
    icon: IconProp
    value: string | undefined
    isVisible: boolean
  }[] = useMemo(
    () => [
      {
        label: 'Doprava',
        icon: faTruckFast,
        value: order.carrier?.name,
        isVisible: !!order.carrier?.name,
      },
      {
        label: 'Zakaznik',
        icon: faUserVneck,
        value: `${order.shippingAddress?.firstName} ${order.shippingAddress?.lastName}`,
        isVisible: !!order.shippingAddress?.firstName || !!order.shippingAddress?.lastName,
      },
      {
        label: 'Datum',
        icon: faCalendar,
        value: `${formatDate(order?.dateCreated, false)} (${dayjs(order?.dateCreated).fromNow()})`,
        isVisible: !!order?.dateCreated,
      },
      {
        label: 'Cena',
        icon: faReceiptSolid,
        value: formatPrice(order.priceAll || 0, order.currency?.id || 'CZK'),
        isVisible: !!order.priceAll && !!order.currency?.id,
      },
    ],
    [],
  )

  const renderOrderDetailItems = () => {
    let iconColor = tinycolor.mix(tinycolor(order.carrier?.color || 'rgb(150, 150, 150)'), '#fff', 80).toString()

    for (let i = 0; i < 100; i++) {
      if (tinycolor.readability(iconColor, color) > 1.6) {
        break
      }
      iconColor = tinycolor(iconColor).darken(1).toString()
    }

    return (
      <div className="p-3">
        {orderDetailItems.map((el, i) => (
          <div className={'flex flex-row items-center gap-3'} key={el.label}>
            <div className={'flex w-7 h-7 items-center justify-center'}>
              <FontAwesomeIcon icon={el.icon} color={iconColor} size={'lg'} />
            </div>
            <span className={'font-semibold'} style={!el.isVisible ? { color: 'rgba(0, 0, 0, 0.3)' } : {}}>
              {el.isVisible ? el.value : 'Nezadano'}
            </span>
          </div>
        ))}
      </div>
    )
  }

  return (
    <Link
      to={`/order/${order.id}`}
      className="flex rounded-lg flex-1 flex-col shadow-md"
      style={{ backgroundColor: color, breakInside: 'avoid' }}>
      <div className={'flex flex-row gap-3 items-center justify-between px-3 py-2 rounded-t-lg bg-gray-50 orderShadow'}>
        <div className={'flex flex-row gap-3 items-center '}>
          {/*<ProcessStatusIcon status={order.processStatus?.id || 'received'} />*/}
          <span className={'font-bold text-lg'}>#{reservationNumber}</span>
        </div>
        <div className={'flex flex-row gap-3 items-center'}>{renderStatusIcons()}</div>
      </div>
      {renderOrderDetailItems()}
    </Link>
  )
}
