import React, { ForwardedRef, forwardRef, useEffect, useRef, useState } from 'react'
import { Fn } from 'depoto-core'

export interface FormInputProps {
  className?: string
  inputclassName?: string
  isDisabled?: boolean
  placeholder?: string
  type?: 'text' | 'number' | 'password' | 'date'
  label?: string
  value?: string
  debounceMs?: number
  min?: number
  max?: number
  blurOnInit?: boolean
  onChange?: Fn
  onSubmit?: Fn
  onBlur?: Fn
  error?: string
}

// export const FormInput: React.FC<Props> = forwardRef<HTMLInputElement, Props>(({
export const FormInput = forwardRef<HTMLInputElement, FormInputProps>(
  (
    {
      className,
      isDisabled,
      inputclassName,
      label,
      placeholder,
      type = 'text',
      children,
      value,
      debounceMs,
      min,
      max,
      blurOnInit,
      onChange,
      onSubmit,
      onBlur,
      error,
    },
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const [inputValue, setInputValue] = useState<string>(value || '')
    const inputRef = useRef<HTMLInputElement>(null)
    // useEffect(() => {
    //   if (ref) {
    //     ref = inputRef
    //   }
    // }, [inputRef.current])

    useEffect(() => {
      if (blurOnInit && inputRef?.current) {
        setTimeout(() => inputRef?.current?.blur(), 50) // fukin helllllllll!!!!!! wtf how is this even remotely logical????!?!??!
      }
    }, [])

    useEffect(() => {
      if (debounceMs) {
        const delayInputTimeoutId = setTimeout(() => {
          typeof onChange === 'function' ? onChange(inputValue) : null
        }, debounceMs)
        return () => clearTimeout(delayInputTimeoutId)
      } else {
        if (typeof onChange === 'function' && inputValue) {
          onChange(inputValue)
        }
      }
    }, [inputValue, debounceMs])

    useEffect(() => {
      setInputValue(value || '')
    }, [value])

    return (
      <div className={className === undefined ? 'form-control' : className + ' form-control '}>
        <label className={label === undefined ? 'none' : ''}>{label}</label>
        <input
          // ref={ref}
          ref={inputRef}
          type={type}
          placeholder={placeholder === undefined ? '' : placeholder}
          className={inputclassName === undefined ? 'input' : inputclassName + ' input'}
          disabled={isDisabled}
          value={inputValue}
          min={min}
          max={max}
          // onChange={ev => (typeof onChange === 'function' ? onChange(ev.target.value) : null)}
          onChange={ev => setInputValue(ev.target.value)}
          onKeyDown={ev => (ev.key === 'Enter' && typeof onSubmit === 'function' ? onSubmit() : null)}
          onBlur={ev => (typeof onBlur === 'function' ? onBlur(ev.target.value) : null)}
        />
        {!!error && <span className={'text-red-500'}>{error}</span>}
      </div>
    )
  },
)
